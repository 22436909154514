import { ChangeEvent, FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import OneIdButton from '../../../components/authorization/OneIdButton'
import Button from '../../../components/common/Button'
import Input from '../../../components/common/Input'
import './styles.sass'

const defaultInputsValues = {
  phoneNumber: '',
  password: ''
}

const SignIn = () => {
  const navigate = useNavigate();
  
  const [formFieldsValue, setFormFieldsValue] = useState(defaultInputsValues);
  const [formError, setFormError] = useState('');
  const [authPending, setAuthPending] = useState(false);
  
  // Handlers
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormFieldsValue({
      ...formFieldsValue,
      [name]: value
    })
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if(formFieldsValue.password && formFieldsValue.phoneNumber) {
      setAuthPending(true);
      console.log('form fields value:', formFieldsValue)
      // This line is just for auth immitation in prod it will be removed
      setTimeout(() => {
        setAuthPending(false);
        navigate('/dashboard')
      }, 2500)
    } else {
      setFormError('Fill the fields');
    }
  }

  const navigateTo = (path: string) => () => {
    navigate(path);
  }
  
  return (
    <div className="page-sign-in">
      <h1>Akkauntga kirish</h1>

      <form onSubmit={onSubmit}>
        <Input 
          name='phoneNumber'
          label='Telefon raqam'
          placeholder='+998 (99) 111-22-33'
          value={formFieldsValue.phoneNumber}
          onChange={onChangeHandler}
          error={!!formError}
        />
        <Input 
          name='password'
          label='Parol'
          placeholder='******'
          type='password'
          value={formFieldsValue.password}
          onChange={onChangeHandler}
          error={!!formError}
        />
        {formError && <p className='form-error'>{formError}</p>}

        <Button variant='underline' style={{marginTop: 16}} onClick={navigateTo('/auth/restore')}>
          Parolni unutdingizmi?
        </Button>

        <Button isLoading={authPending} type='submit' fullWidth size='lg' style={{marginTop: 50}}>
          Kirish
        </Button>

        <div className='page-sign-in__question'>
          <p>
            Akkauntingiz mavjud emasmi?{' '}

            <span className='text-underline' onClick={navigateTo('/auth/sign-up')}>
              Ro'yxatdan o'tish
            </span>
          </p>

          <div>
            <hr />Yoki<hr />
          </div>
        </div>
        
        <OneIdButton />
      </form>
    </div>
  )
}

export default SignIn