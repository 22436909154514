// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-compaines-create {
  padding: 20px 16px 24px 24px;
  min-height: calc(100% - 73px);
}
.page-compaines-create .combobox {
  flex: 1 1;
}
.page-compaines-create .amount-picker {
  flex: 1 1;
}
.page-compaines-create .create-requisite-btn {
  margin-top: 16px;
  color: var(--primary);
  gap: 4px;
  font-weight: 600;
}
.page-compaines-create .create-requisite-btn .button__icon-left {
  width: 24px;
  height: 24px;
}
.page-compaines-create .create-requisite-btn .button__icon-left svg {
  stroke: var(--primary);
}
.page-compaines-create .remove-btn {
  padding: 13px 0;
}
.page-compaines-create .remove-btn:disabled svg {
  stroke: var(--border-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/Companies/Create/styles.sass"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,6BAAA;AACF;AACE;EACE,SAAA;AACJ;AACE;EACE,SAAA;AACJ;AACE;EACE,gBAAA;EACA,qBAAA;EACA,QAAA;EACA,gBAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;AACN;AAAM;EACE,sBAAA;AAER;AAAE;EACE,eAAA;AAEJ;AACM;EACE,2BAAA;AACR","sourcesContent":[".page-compaines-create\n  padding: 20px 16px 24px 24px\n  min-height: calc(100% - 73px)\n\n  .combobox\n    flex: 1\n\n  .amount-picker\n    flex: 1\n\n  .create-requisite-btn\n    margin-top: 16px\n    color: var(--primary)\n    gap: 4px\n    font-weight: 600\n\n    .button__icon-left\n      width: 24px\n      height: 24px\n      svg\n        stroke: var(--primary)\n\n  .remove-btn\n    padding: 13px 0\n\n    &:disabled\n      svg\n        stroke: var(--border-color)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
