import {ReactNode} from "react";
import {NavLink} from "react-router-dom";

export type NavLinkBasic = {
  to: string;
  icon?: ReactNode;
  text: string;
};

const NavItem = ({to, text, icon}: NavLinkBasic) => {
  return (
    <NavLink to={to}>
      <div className="page-nav__item">
        {icon && (
          <div className="page-nav__icon">
            {icon}
          </div>
        )}
        {text}
      </div>
    </NavLink>
  );
};

export default NavItem;
