import AmountPicker from '../../../../../components/common/AmountPicker';
import { CargoAmounts } from '../../../../../utils/consts';

interface CargoOwnerTabProps {
  selectedAmount: string;
  setSelectedAmount: (amount: string) => void;
}

const CargoOwnerTab = ({selectedAmount, setSelectedAmount}: CargoOwnerTabProps) => {
  return (
    <div>
      <AmountPicker
        label='Oyiga qancha  yuk chiqarasiz? (tonna)'
        amounts={CargoAmounts}
        selectedAmount={selectedAmount}
        onAmountClick={(amount: string) => {
          setSelectedAmount(amount);
        }}
      />
    </div>
  )
}

export default CargoOwnerTab