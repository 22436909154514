import { Navigate, Route, Routes } from 'react-router-dom'
import CompanyInfo from './SubPages/CompanyInfo'
import CompanyDocs from './SubPages/CompanyDocs'
import PageNavigation, { NavigationItem } from '../../../components/common/PageNavigation'
import './styles.sass'
import CompanyDrivers from './SubPages/CompanyDrivers'
import CompanyTransports from './SubPages/CompanyTransports'
import NotFound from '../../NotFound/NotFound'

const links: NavigationItem[] = [
  {
    type: 'link',
    to: '../info',
    text: "Korxona ma'lumotlari"
  },
  {
    type: 'link',
    to: '../docs',
    text: 'Dokumentlar'
  },
  {
    type: 'link',
    to: '../drivers',
    text: 'Haydovchilar'
  },
  {
    type: 'link',
    to: '../transports',
    text: 'Transportlar'
  }
]

const navComponent = <PageNavigation links={links} />

const ViewCompanies = () => {
  return (
    <Routes>
      <Route path='info' element={ <CompanyInfo navigationSlot={navComponent} /> } />
      <Route path='docs'element={ <CompanyDocs navigationSlot={navComponent} /> } />
      <Route path='drivers' element={ <CompanyDrivers navigationSlot={navComponent} /> } />
      <Route path='transports' element={ <CompanyTransports navigationSlot={navComponent} />} />
      <Route path='/' element={<Navigate to='info' />}  />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default ViewCompanies