// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  background: #FFFFFF;
  border-bottom: 1px solid #E8E8E8;
}
.header__wrapper {
  display: flex;
  gap: 24px;
}
.header__button {
  width: 24px;
  height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/Header/styles.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,gCAAA;AACF;AACE;EACE,aAAA;EACA,SAAA;AACJ;AACE;EACE,WAAA;EACA,YAAA;AACJ","sourcesContent":[".header\n  padding: 24px\n  display: flex\n  justify-content: flex-end\n  background: #FFFFFF\n  border-bottom: 1px solid #E8E8E8\n\n  &__wrapper\n    display: flex\n    gap: 24px\n\n  &__button\n    width: 24px\n    height: 24px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
