import { useState } from 'react'
import RoleCard from '../../../components/authorization/RoleCard'
import FlexWrapper from '../../../components/common/FlexWrapper'
import ProgressBar from '../../../components/common/ProgressBar'
import Combobox from '../../../components/common/Combobox'
import Button from '../../../components/common/Button'
import LogisticianTab from './RolesTabs/LogisticianTab'
import TruckDeliveryTab from './RolesTabs/TruckDeliveryTab'
import CargoOwnerTab from './RolesTabs/CargoOwnerTab'
import { RoleCards } from '../../../utils/consts'
import { ROLES } from '../../../types/roles.types'
import './styles.sass'

const Addtional = () => {
  const [selectedRole, setSelectedRole] = useState<ROLES | null>(null);
  const [selectedAmount, setSelectedAmount] = useState('');

  const handleRoleClick = (role: ROLES) => {
    setSelectedRole(role);
  };

  return (
    <div className='page-additional'>
      <h1 className="page-additional__title">Qo'shimcha ma'lumotlar</h1>

      <ProgressBar currentStep={2} style={{marginTop: 24}} />
      
      <form className='form'>
        <h4 className="form__title">
          Rolingizni tanlang
        </h4>

        <FlexWrapper style={{marginTop: 16}}>
          {RoleCards.map(({ iconUrl, title, role }) => (
            <RoleCard
              key={role}
              iconUrl={iconUrl}
              title={title}
              isActive={selectedRole === role}
              onClick={() => handleRoleClick(role)}
            />
          ))}
        </FlexWrapper>

        <div className="tabs-wrapper">
          {selectedRole === ROLES.LOGISTICIAN && (
            <LogisticianTab />
          )}
          {selectedRole === ROLES.TRUCK_DELIVERY && (
            <TruckDeliveryTab />
          )}
          {selectedRole === ROLES.CARGO_OWNER && (
            <CargoOwnerTab selectedAmount={selectedAmount} setSelectedAmount={setSelectedAmount} />
          )}
        </div>

        <Combobox
          label='Biz haqimizda qayerdan eshitdingiz?'
          options={[
            {label: 'Do\'stim taklif qildi', value: 0},
            {label: 'Ijtimoiy tarmoqlardan', value: 1},
            {label: 'Websayt sahifalaridan', value: 2}
          ]}
          onSelect={(value) => {console.log(value)}}
        />

        <Button type='submit' fullWidth size='lg' disabled style={{marginTop: 24}}>
          Yakunlash
        </Button>
      </form>
      
    </div>
  )
}

export default Addtional