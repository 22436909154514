import {useNavigate} from "react-router-dom";
import PageInfo from "../../../components/dashboard/PageInfo";
import Button from "../../../components/common/Button";
import ContentWrapper from "../../../components/common/ContentWrapper";
import FlexWrapper from "../../../components/common/FlexWrapper";
import Input from "../../../components/common/Input";
import DragNDrop from "../../../components/common/DragNDrop";
import Combobox from "../../../components/common/Combobox";
import RadioInput from "../../../components/common/RadioInput";
import './styles.sass';

const TrailersCreate = () => {
  const navigate = useNavigate();

  return (
    <div className='page-trucks-create'>
      <PageInfo
        title='Yangi tirkama qo’shish'
        backButton
        buttonsSlot={
          <Button onClick={() => navigate(-1)}>
            Saqlash
          </Button>
        }
      />

      <ContentWrapper rowGap={16}>
        <h3 className="section-title">Tirkama ma’lumotlari</h3>

        <FlexWrapper>
          <Input label='Nomi' placeholder='Nomini  kiriting' />
          <Input label='Tirkama modeli' placeholder='Tirkama modelini kiriting' />
          <Combobox label='Ishlab chiqarilgan yili' placeholder='Yilini tanlang' />
        </FlexWrapper>

        <FlexWrapper>
          <Input label='Texnik passporti seriyasi va raqami' placeholder='AA 123 44 55' />
          <Combobox label='Turi' placeholder='Turi' />
          <Combobox label='Statusi' placeholder='Statusni tanlang' />
        </FlexWrapper>

        <FlexWrapper style={{marginBottom: 24}}>
          <DragNDrop containerSize='sm' label='Texnik passporti (old tomoni)' />
          <DragNDrop containerSize='sm' label='Texnik passporti (orqa tomoni)' />
        </FlexWrapper>
      </ContentWrapper>

      <ContentWrapper style={{marginTop: 16}}>
        <h3 className="section-title">Muvofiqliklar</h3>

        <FlexWrapper label='Yo’naltirilganlik statusi'>
          <RadioInput label="Aktive" />
          <RadioInput label='Aktiv emas' />
        </FlexWrapper>

        <FlexWrapper>
          <Combobox label='Yo’naltirilgan yuk mashinasi' placeholder='Mashinani tanlang' />
          <Combobox label='Yo’naltirilgan haydovchi' placeholder='Haydovchi tanlang' />
          <Input label='Haydovchi manzili' placeholder='Tirkama nomi' />
        </FlexWrapper>

        <FlexWrapper className='wrapper-grid'>
          <Input label='Eslatma' placeholder='Eslatma kiriting' />
        </FlexWrapper>
      </ContentWrapper>
    </div>
  )
};

export default TrailersCreate;
