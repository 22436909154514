import { ChangeEvent, KeyboardEvent } from 'react';
import classNames from 'classnames';
import './styles.sass';

interface OtpProps {
  className?: string;
  error?: boolean;
  otp: string[];
  setOtp: (otps: string[]) => void;
}

const OTP = ({ otp, setOtp, className, error }: OtpProps) => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>, inputIdx: number) => {
    const input = e.target;
    const inputValue = input.value;
    
    if (/^\d$/.test(inputValue)) {
      const newOtp = otp.map((code, idx) => (idx === inputIdx ? inputValue : code));
      setOtp(newOtp);

      // Move focus to the next input
      if (input.nextSibling && input.nextSibling instanceof HTMLElement) {
        input.nextSibling.focus();
      }
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, inputIndex: number) => {
    const input = e.currentTarget;

    if (e.key === "Backspace") {
        e.preventDefault();
        const newOtp = otp.map((code, idx) => (idx === inputIndex ? "" : code))
        setOtp(newOtp);
        const previousSibling = input.previousSibling;

        if (previousSibling && previousSibling instanceof HTMLElement) {
            previousSibling.focus();
        }
    }
};

  return (
    <div className={classNames('otp', className)}>
      {otp.map((code, idx) => (
        <input
          className={classNames('otp__input', {'otp__input--error': error})}
          type="text"
          maxLength={1}
          value={code}
          onChange={(e) => onChangeHandler(e, idx)}
          onKeyDown={(e) => handleKeyDown(e, idx)}
          onFocus={(e) => e.target.select()}
          key={idx}
        />
      ))}
    </div>
  );
}

export default OTP;
