// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-new-password {
  max-width: 500px;
  width: 100%;
  padding: 48px 0;
}
.page-new-password__title {
  margin: 0;
  color: #172B4D;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
}
.page-new-password form {
  margin-top: 56px;
}
.page-new-password .input-container:nth-child(2) {
  margin-top: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/NewPassword/styles.sass"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,eAAA;AACF;AACE;EACE,SAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACE;EACE,gBAAA;AACJ;AAEI;EACE,gBAAA;AAAN","sourcesContent":[".page-new-password\n  max-width: 500px\n  width: 100%\n  padding: 48px 0\n  \n  &__title\n    margin: 0\n    color: #172B4D\n    font-size: 28px\n    font-weight: 700\n    line-height: 1.6\n    text-align: center\n\n  form\n    margin-top: 56px\n  \n  .input-container\n    &:nth-child(2)\n      margin-top: 16px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
