// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.truck-delivery-tab .content-wrapper {
  margin-top: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Additional/RolesTabs/TruckDeliveryTab/styles.sass"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ","sourcesContent":[".truck-delivery-tab\n  .content-wrapper\n    margin-top: 16px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
