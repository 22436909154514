import classNames from 'classnames';
import './styles.sass';

interface RoleCardProps {
  iconUrl?: string;
  title: string;
  isActive?: boolean;
  onClick?: () => void;
}

const RoleCard = ({iconUrl, title, isActive = false, onClick}: RoleCardProps) => {
  const classes = classNames('role-card', {'role-card--active': isActive})
  
  return (
    <div className={classes} onClick={onClick}>
      <div className="role-card__icon">
        <img src={iconUrl} alt='icon' />
      </div>
      <div className="role-card__title">{title}</div>
    </div>
  )
}

export default RoleCard