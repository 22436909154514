import { ChangeEvent, FormEvent, useState } from 'react';
import Input from '../../../components/common/Input'
import './styles.sass'
import Button from '../../../components/common/Button';
import { useNavigate } from 'react-router-dom';

const Restore = () => {
  const navigate = useNavigate();
  
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formError, setFormError] = useState('');

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if(phoneNumber) {
      console.log(phoneNumber)
      navigate('/auth/verification', {
        state: {phoneNumber}
      });
    } else {
      setFormError('Enter the phone number');
    }
  }

  return (
    <div className='page-restore'>
      <h1 className='page-restore__title'>Parolingizni unutdingizmi?</h1>
      <p className='page-restore__descr'>
        Iltimos, qabul qilish uchun telefon <br/> 
        raqamingizni kiriting.
      </p>

      <form onSubmit={onSubmit}>
        <Input 
          label='Telefon raqam' 
          placeholder="+998 (__) ___ __ __"
          value={phoneNumber} 
          onChange={onChangeHandler}
          error={!!formError}
        />
        {formError && <p className='form-error'>{formError}</p>}

        <Button fullWidth size='lg' type='submit' style={{marginTop: 40}}>
          Yuborish
        </Button>
      </form>
    </div>
  )
}

export default Restore