import {ReactNode, useState} from "react";
import NavItem, {NavLinkBasic} from "../NavItem";
import classNames from "classnames";

export type NavDropdownProps = {
  icon?: ReactNode;
  text: string;
  links: NavLinkBasic[];
};

const NavDropdown = ({ text, icon, links}: NavDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classNames('page-nav__dropdown', {
      'open': isOpen
    })}>
      <div className="dropdown__header" onClick={() => setIsOpen(!isOpen)}>
        {icon && (
          <div className="page-nav__icon">
            {icon}
          </div>
        )}
        {text}
      </div>

      <div className={classNames("dropdown__body", {'open': isOpen})}>
        {links.map(({to, text, icon},idx) => (
          <NavItem to={to} key={idx} text={text} icon={icon} />
        ))}
      </div>
    </div>
  );
};

export default NavDropdown;
