import './styles.sass'

const Footer = () => {
  return (
    <footer className='auth-footer'>
      <div className='auth-footer__container'>
        <p className='auth-footer__text'>©2024 AIRCUZ</p>
        <p className='auth-footer__text'>Barcha huquqlar himoyalangan.</p>
      </div>
    </footer>
  )
}

export default Footer