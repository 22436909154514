import NavItem, {NavLinkBasic} from "./NavItem";
import NavDropDown, {NavDropdownProps} from "./NavDropDown";
import './styles.sass'


type NavLinkType = NavLinkBasic & {
  type: 'link';
}

type NavDropdownType = NavDropdownProps & {
  type: 'dropdown'
}

export type NavigationItem = NavLinkType | NavDropdownType;

interface PageNavigationProps {
  links: NavigationItem[];
}

const PageNavigation = ({ links }: PageNavigationProps) => {

  return (
    <div className="page-nav">
      {links.map((link) => {
        const { type, text, icon } = link

        if(type === 'link')
          return <NavItem to={link.to} key={link.to} text={text} icon={icon} />

        return <NavDropDown text={text} links={link.links} key={text} />
      })}
    </div>
  )
}

export default PageNavigation
