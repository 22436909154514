import { ReactNode, ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import './styles.sass';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  variant?: 'primary' | 'secondary' | 'outline' | 'underline' | 'clear';
  size?: 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
  isLoading?: boolean;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  children,
  className = '',
  variant = 'primary',
  size = 'md',
  fullWidth = false,
  isLoading = false,
  disabled = false,
  onClick,
  iconLeft,
  iconRight,
  type = 'button',
  ...props
}) => {
  const classes = classNames(
    'button',
    `button--${variant}`,
    `button--${size}`,
    {
      'button--loading': isLoading,
      'button--disabled': disabled,
      'button--full-width': fullWidth
    },
    className
  );

  return (
    <button
      className={classes}
      onClick={onClick}
      disabled={disabled || isLoading}
      type={type}
      {...props}
    >
      {iconLeft && <span className="button__icon-left">{iconLeft}</span>}
      {isLoading ? 'Loading...' : children}
      {iconRight && <span className="button__icon-right">{iconRight}</span>}
    </button>
  );
};

export default Button;
