import {useNavigate} from "react-router-dom";
import PageInfo from "../../../components/dashboard/PageInfo";
import Button from "../../../components/common/Button";
import ContentWrapper from "../../../components/common/ContentWrapper";
import FlexWrapper from "../../../components/common/FlexWrapper";
import Input from "../../../components/common/Input";
import DragNDrop from "../../../components/common/DragNDrop";
import Combobox from "../../../components/common/Combobox";
import RadioInput from "../../../components/common/RadioInput";
import './styles.sass';

const TrucksCreate = () => {
  const navigate = useNavigate();

  return (
    <div className='page-trucks-create'>
      <PageInfo
        title='Yangi yuk mashina qo’shish'
        backButton
        buttonsSlot={
          <Button onClick={() => navigate(-1)}>
            Saqlash
          </Button>
        }
      />

      <ContentWrapper rowGap={16}>
        <h3 className="section-title">Mashina ma’lumotlari</h3>

        <FlexWrapper>
          <Input label='Mashina nomi' placeholder='Nomini  kiriting' />
          <Input label='Mashina modeli' placeholder='Mashina modelini kiriting' />
          <Combobox label='Ishlab chiqarilgan yili' placeholder='Yilini tanlang' />
        </FlexWrapper>

        <FlexWrapper>
          <Input label='Mashina texnik passporti seriyasi va raqami' placeholder='AA 123 44 55' />
          <Input label='Mashina raqami' placeholder='01 A 000 AA' />
        </FlexWrapper>

        <FlexWrapper style={{marginBottom: 24}}>
          <DragNDrop containerSize='sm' label='Mashina texnik passporti (old tomoni)' />
          <DragNDrop containerSize='sm' label='Mashina tehnik passporti (orqa tomoni)' />
        </FlexWrapper>
      </ContentWrapper>

      <ContentWrapper style={{marginTop: 16}}>
        <h3 className="section-title">Muvofiqliklar</h3>

        <FlexWrapper label='Yo’naltirilganlik statusi'>
          <RadioInput label="Aktive" />
          <RadioInput label='Aktiv emas' />
        </FlexWrapper>

        <FlexWrapper>
          <Combobox label='Yo’naltirilgan tirkama' placeholder='Tirkama tanlang' />
          <Combobox label='Yo’naltirilgan haydovchi' placeholder='Haydovchi tanlang' />
          <Input label='Haydovchi manzili' placeholder='Tirkama nomi' />
        </FlexWrapper>

        <FlexWrapper>
          <Input label='Eslatma' placeholder='Eslatma kiriting' className='wrapper-grid' />
        </FlexWrapper>
      </ContentWrapper>
    </div>
  )
};

export default TrucksCreate;
