// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 44px;
  border-top: 1px solid #DFDFDF;
}
.auth-footer__text {
  margin: 0;
  color: var(--secondary);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
}`, "",{"version":3,"sources":["webpack://./src/components/authorization/Footer/styles.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,6BAAA;AACF;AACE;EACE,SAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACJ","sourcesContent":[".auth-footer\n  display: flex\n  justify-content: center\n  align-items: center\n  padding: 22px 44px\n  border-top: 1px solid #DFDFDF\n\n  &__text\n    margin: 0\n    color: var(--secondary)\n    text-align: center\n    font-size: 14px\n    font-weight: 400\n    line-height: 1.3"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
