import { Placemark } from "@pbe/react-yandex-maps"
import YandexMap from "../../../components/dashboard/Map"
import { cargoIcon, driverIcon, truckIcon } from "../../../utils/consts"
import { ReactNode } from "react"
import FlexWrapper from "../../../components/common/FlexWrapper"
import PageInfo from "../../../components/dashboard/PageInfo"

// coordinates.ts
export const cargoMarkers: [number, number][] = [
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
];

export const driverMarkers: [number, number][] = [
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
];

export const truckMarkers: [number, number][] = [
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
  [41.311365 + (Math.random() - 0.5) * 0.1, 69.279593 + (Math.random() - 0.5) * 0.1],
];

const AllItems = ({navigationSlot}: {navigationSlot: ReactNode}) => {
  return (
    <div className="page-maps-view">
      <PageInfo backButton title="Xaritalar" />
      <FlexWrapper>
        {navigationSlot}
        
        <div className="page-content">
          <YandexMap>
            
            {/* Отображение маркеров для cargoIcon */}
        {cargoMarkers.map((coords, index) => (
          <Placemark
            key={`cargo-${index}`}
            geometry={coords}
            options={{
              iconLayout: 'default#image',
              iconImageHref: cargoIcon,
              iconImageSize: [60, 62],
              iconImageOffset: [-30, -62],
            }}
          />
        ))}
        
        {/* Отображение маркеров для driverIcon */}
        {driverMarkers.map((coords, index) => (
          <Placemark
            key={`driver-${index}`}
            geometry={coords}
            options={{
              iconLayout: 'default#image',
              iconImageHref: driverIcon,
              iconImageSize: [60, 62],
              iconImageOffset: [-30, -62],
            }}
          />
        ))}

        {/* Отображение маркеров для truckIcon */}
        {truckMarkers.map((coords, index) => (
          <Placemark
            key={`truck-${index}`}
            geometry={coords}
            options={{
              iconLayout: 'default#image',
              iconImageHref: truckIcon,
              iconImageSize: [60, 62],
              iconImageOffset: [-30, -62],
            }}
          />
        ))}
            
          </YandexMap>
        </div>

      </FlexWrapper>
    </div>
  )
}

export default AllItems