import { FC, HtmlHTMLAttributes } from 'react';
import './styles.sass';
import classNames from 'classnames';

interface ProgressBarProps extends HtmlHTMLAttributes<HTMLDivElement> {
  size?: 'sm' | 'md';
  stepsCount?: number;
  currentStep?: number;
}

const ProgressBar: FC<ProgressBarProps> = ({ 
  size = 'md',
  stepsCount = 2,
  currentStep = 1,
  className,
  style
}) => {
  const stepsArray = Array.from({ length: stepsCount }, (_, index) => index + 1);
  const classes = classNames(
    'progress-bar', 
    `progress-bar--${size}`,
    className
  )
  
  return (
    <div className={classes} style={style}>
      {stepsArray.map((value) => (
          <div className="progress-bar__track" key={value}>
            <div 
              className="progress-bar__progress" 
              style={{ width: `${currentStep >= value ? '100%' : 0}` }}
            />
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
