// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-restore {
  max-width: 500px;
  width: 100%;
  padding: 48px 0;
}
.page-restore__title {
  margin: 0;
  color: #172B4D;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
}
.page-restore__descr {
  margin: 0;
  margin-top: 16px;
  color: #7E7E7E;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
}
.page-restore .input-container {
  margin-top: 32px;
}
.page-restore .form-error {
  margin: 0;
  margin-top: 4px;
  color: var(--error-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Restore/styles.sass"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,eAAA;AACF;AACE;EACE,SAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACE;EACE,SAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACE;EACE,gBAAA;AACJ;AACE;EACE,SAAA;EACA,eAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACJ","sourcesContent":[".page-restore\n  max-width: 500px\n  width: 100%\n  padding: 48px 0\n\n  &__title\n    margin: 0\n    color: #172B4D\n    font-size: 28px\n    font-weight: 700\n    line-height: 1.6\n    text-align: center\n\n  &__descr\n    margin: 0\n    margin-top: 16px\n    color: #7E7E7E\n    font-size: 18px\n    font-weight: 400\n    line-height: 1.5\n    text-align: center\n\n  .input-container\n    margin-top: 32px\n\n  .form-error\n    margin: 0\n    margin-top: 4px\n    color: var(--error-color)\n    font-size: 14px\n    font-weight: 400\n    line-height: 1.6"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
