import { ReactNode } from 'react'
import PageInfo from '../../../../../components/dashboard/PageInfo'
import FlexWrapper from '../../../../../components/common/FlexWrapper'

const CompanyTransports = ({ navigationSlot }: {navigationSlot: ReactNode}) => {
  return (
    <div className='page-companies-view'>
      <PageInfo 
        title='OOO UMID AVTO LOGISTICS'
        backButton
        url='/dashboard/companies'
      />

      <FlexWrapper>
        {navigationSlot}

        <div className="page-content">
          Transports page content
        </div>
      </FlexWrapper>
    </div>
  )
}

export default CompanyTransports