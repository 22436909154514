import { ReactComponent as LogoIconColored } from '../../../assets/global/logo-icon-colored.svg'
import { ReactComponent as LogoIconWhite } from '../../../assets/global/logo-icon-white.svg'
import { ReactComponent as LogoNew } from '../../../assets/global/new-logo.svg'
import { ReactComponent as LogoNewColored } from '../../../assets/global/new-logo-light-bg.svg'
import './styles.sass'

type Props = {
	className?: string
	type?: 'full' | 'icon'
	viewStyle?: 'colored' | 'white'
	width?: string
	height?: string
}

const Logo = ({ type = 'full', viewStyle = 'colored', width, height, className }: Props) => {
	return (
		<div className={`logo logo-${type} ${className}`} style={{width, height}}>
			{type === 'full' && viewStyle === 'colored' && <LogoNewColored />}
			{type === 'full' && viewStyle === 'white' && <LogoNew />}
			{type === 'icon' && viewStyle === 'colored' && <LogoIconColored />}
			{type === 'icon' && viewStyle === 'white' && <LogoIconWhite />}
		</div>
	)
}

export default Logo
