import { useNavigate } from 'react-router-dom'
import Logo from '../../common/Logo'
import UserAvatar from '../../../assets/temporary/user-avatar.png'
import { ReactComponent as DashboardIcon } from '../../../assets/global/sidebar-icons/dashboard.svg'
import { ReactComponent as BoxIcon } from '../../../assets/global/sidebar-icons/box.svg'
import { ReactComponent as BuildingIcon } from '../../../assets/global/sidebar-icons/building.svg'
import { ReactComponent as TruckIcon } from '../../../assets/global/sidebar-icons/truck.svg'
import { ReactComponent as MapIcon } from '../../../assets/global/sidebar-icons/map.svg'
import { ReactComponent as DocIcon } from '../../../assets/global/sidebar-icons/news.svg'
import { ReactComponent as SettingsIcon } from '../../../assets/global/sidebar-icons/settings.svg'
import './styles.sass'
import useModal from '../../../hooks/useModal'
import { Modal } from '../../common/Modal'
import { useTranslation } from 'react-i18next'

const Sidebar = () => {
	const navigate = useNavigate();
	const { openModal, isOpen, closeModal } = useModal();
	const { t } = useTranslation();
	
	return (
		<div className='sidebar'>
			<div className='sidebar-header'>
				<Logo type='full' viewStyle='white' width='150px' height='100px' />
			</div>
			<div className='sidebar-navigation'>

				<div className='sidebar-navigation__item' onClick={openModal}>
					<span className="navigation__icon">
						<DashboardIcon />
					</span>
					<div className="navigation__text">Dashboard</div>
				</div>

				<div className='sidebar-navigation__item' onClick={openModal}>
					<span className="navigation__icon navigation__icon--with-stroke">
						<BoxIcon />
					</span>
					<div className="navigation__text">Yuklar</div>
				</div>

				<div className='sidebar-navigation__item' onClick={() => navigate('companies')}>
					<span className="navigation__icon">
						<BuildingIcon />
					</span>
					<div className="navigation__text">Korxonalar</div>
				</div>

				<div className='sidebar-navigation__item' onClick={openModal}>
					<span className="navigation__icon">
						<TruckIcon />
					</span>
					<div className="navigation__text">Transportlar boshq...</div>
				</div>

				<div className='sidebar-navigation__item' onClick={() => navigate('maps')}>
					<span className="navigation__icon navigation__icon--with-stroke">
						<MapIcon />
					</span>
					<div className="navigation__text">Xaritalar</div>
				</div>

				<div className='sidebar-navigation__item' onClick={openModal}>
					<span className="navigation__icon">
						<DocIcon />
					</span>
					<div className="navigation__text">Dokumentlar</div>
				</div>

				<div className='sidebar-navigation__item' onClick={openModal}>
					<span className="navigation__icon navigation__icon--with-stroke">
						<SettingsIcon />
					</span>
					<div className="navigation__text">Sozlamalar</div>
				</div>

			</div>
			<div className='sidebar-bottom'>
				<div className='user-information'>
					<div className='user-avatar'>
						<img src={UserAvatar} alt='User avatar' />
					</div>
					<div className='user-contact'>
						<div className='user-contact-fullname'>
							<span>Ahrorbek Mirzaev</span>
						</div>
						<div className='user-contact-phone'>
							<span>+998 (77) 353-88-88</span>
						</div>
					</div>
				</div>
			</div>

			{isOpen && (
				<Modal title={t("Ma'lumot")} titlePosition='center' onClose={closeModal}>
					<div className="modal-routes-info">
						{t('nav-msg')}
					</div>
				</Modal>
			)}
		</div>
	)
}

export default Sidebar
