// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.company-docs .docs-wrapper {
  margin-top: 16px;
}

.modal .create-doc__label {
  margin: 24px 0 12px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
}
.modal .create-doc__label:first-of-type {
  margin-top: 0;
}
.modal .remoev-doc__desc {
  color: #62687B;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Companies/View/SubPages/CompanyDocs/styles.sass"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;;AAGE;EACE,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAAJ;AAEE;EACE,aAAA;AAAJ;AAEE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AAAJ","sourcesContent":[".company-docs\n  .docs-wrapper\n    margin-top: 16px\n\n.modal\n  .create-doc__label\n    margin: 24px 0 12px 0\n    font-size: 18px\n    font-weight: 500\n    line-height: 1.4\n\n  .create-doc__label:first-of-type\n    margin-top: 0\n\n  .remoev-doc__desc\n    color: #62687B\n    font-size: 14px\n    font-weight: 600\n    line-height: 1.4\n    text-align: center"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
