import {JPGIcon, TrashIcon, UploadIcon} from "../../../utils/icons";
import Button from "../Button";
import './styles.sass'

interface FileViewProps {
  label?: string;
  fileName: string;
  onRemove?: Function;
  onShare?: Function;
}

const FileViewField = ({label, fileName, onShare, onRemove}: FileViewProps) => {
  return (
    <div className="file-view">
      {label && (
        <p className='file-view__label'>
          {label}
        </p>)
      }

      <div className="file-view__wrapper">
        <span className="file-view__icon">
          <JPGIcon />
        </span>

        <div className="file-view__content">
          <span className="file-view__file-name">{fileName}</span>

          <div className="file-view__btns">
            <Button variant='clear'>
              <UploadIcon/>
            </Button>
            <Button variant='clear'>
              <TrashIcon/>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileViewField;
