// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-view {
  flex: 1 1;
}
.file-view__label {
  margin: 0 0 8px;
  color: #4A5972;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
}
.file-view__wrapper {
  display: flex;
  gap: 8px;
  padding: 14px 16px;
  border-radius: 4px;
  border: 1px solid #E6E8EC;
}
.file-view__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
.file-view__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
}
.file-view__file-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  color: var(--text-default);
}
.file-view__btns {
  display: flex;
  gap: 16px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/common/FileView/styles.sass"],"names":[],"mappings":"AAAA;EACE,SAAA;AACF;AACE;EACE,eAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AACE;EACE,aAAA;EACA,QAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;AACJ;AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;AACJ;AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACE;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,0BAAA;AACJ;AACE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AACJ","sourcesContent":[".file-view\n  flex: 1\n\n  &__label\n    margin: 0 0 8px\n    color: #4A5972\n    font-size: 14px\n    font-weight: 500\n    line-height: 1.2\n\n  &__wrapper\n    display: flex\n    gap: 8px\n    padding: 14px 16px\n    border-radius: 4px\n    border: 1px solid #E6E8EC\n\n  &__icon\n    display: flex\n    align-items: center\n    justify-content: center\n    width: 24px\n    height: 24px\n\n  &__content\n    display: flex\n    justify-content: space-between\n    align-items: center\n    flex: 1\n\n  &__file-name\n    font-size: 14px\n    font-weight: 500\n    line-height: 1.4\n    color: var(--text-default)\n\n  &__btns\n    display: flex\n    gap: 16px\n    align-items: center\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
