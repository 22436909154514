// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.amount-picker__label {
  margin: 0;
  margin-bottom: 8px;
  color: #2F3437;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
}
.amount-picker__item {
  padding: 8px 12px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  white-space: nowrap;
  min-width: 48px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  transition: border-color 0.2s ease-in;
  cursor: pointer;
}
.amount-picker__item--active, .amount-picker__item:hover {
  border-color: var(--primary);
}`, "",{"version":3,"sources":["webpack://./src/components/common/AmountPicker/styles.sass"],"names":[],"mappings":"AACE;EACE,SAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAAJ;AAEE;EACE,iBAAA;EAEA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EAEA,eAAA;EACA,kBAAA;EACA,qCAAA;EACA,qCAAA;EACA,eAAA;AAFJ;AAII;EAEE,4BAAA;AAHN","sourcesContent":[".amount-picker\n  &__label\n    margin: 0\n    margin-bottom: 8px\n    color: #2F3437\n    font-size: 14px\n    font-weight: 500\n    line-height: 1.4\n  \n  &__item\n    padding: 8px 12px\n\n    color: #000\n    font-size: 16px\n    font-weight: 400\n    line-height: 1.6\n    white-space: nowrap\n\n    min-width: 48px\n    border-radius: 4px\n    border: 1px solid var(--border-color)\n    transition: border-color .2s ease-in\n    cursor: pointer\n\n    &--active,\n    &:hover\n      border-color: var(--primary)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
