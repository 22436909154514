// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-authorization {
  min-height: 100vh;
  background: var(--auth-bg-color);
  display: flex;
  flex-direction: column;
}
.layout-authorization .container {
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/layouts/AuthorizationLayout/styles.sass"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,gCAAA;EAEA,aAAA;EACA,sBAAA;AADJ;AAGI;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AADN","sourcesContent":[".layout\n  &-authorization\n    min-height: 100vh\n    background: var(--auth-bg-color)\n\n    display: flex\n    flex-direction: column\n\n    .container\n      padding: 0 24px\n      display: flex\n      align-items: center\n      justify-content: center\n      flex: 1"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
