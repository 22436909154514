import { ChangeEvent, FormEvent, useState } from 'react'
import Button from '../../../components/common/Button'
import FlexWrapper from '../../../components/common/FlexWrapper'
import Input from '../../../components/common/Input'
import ProgressBar from '../../../components/common/ProgressBar'
import './styles.sass'
import { useNavigate } from 'react-router-dom'

const defaultInputsValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  password: '',
  confirmPassword: ''
}

const SignUp = () => {
  const navigate = useNavigate();
  
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [inputsValues, setInputsValues] = useState(defaultInputsValues);
  const {
    firstName, 
    lastName, 
    phoneNumber, 
    password, 
    confirmPassword
  } = inputsValues;

  const checkPasswordStrength = (password: string) => {
    const conditions = [
      /.{8,}/,
      /(?=.*[a-z])(?=.*[A-Z])/,
      /[0-9]/, // Digit
      /[!@#$%^&*(),.?":{}|<>]/,
    ];

    const passedConditions = conditions.reduce((acc, condition) => acc + (condition.test(password) ? 1 : 0), 0);
    setPasswordStrength(passedConditions);
  }

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if(name === 'password') {
      checkPasswordStrength(value);
    }
    
    setInputsValues({
      ...inputsValues,
      [name]: value
    })
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(inputsValues);
    navigate('/auth/additional-info');
  }
  
  return (
    <div className='page-sign-up'>
      <h1 className="page-sign-up__title">
        Ro'yxatdan o'tish
      </h1>

      <ProgressBar currentStep={1} style={{marginTop: 24}}/>

      <form onSubmit={onSubmit}>
        <FlexWrapper>
          <Input 
            label='Ism'
            name='firstName'
            value={firstName}
            onChange={onChangeHandler}
          />
          <Input 
            label='Familiya'
            name='lastName'
            value={lastName}
            onChange={onChangeHandler}
          />
        </FlexWrapper>
        <Input 
          label='Telefon raqam'
          name='phoneNumber'
          value={phoneNumber}
          onChange={onChangeHandler}
          placeholder='+998 (__) ___ __ __'
        />
        <Input 
          label='Parol'
          type='password'
          name='password'
          value={password}
          onChange={onChangeHandler}
          placeholder='parolni kiriting'
        />
        <Input 
          label='Parolni qayta kiriting'
          type='password'
          name='confirmPassword'
          value={confirmPassword}
          onChange={onChangeHandler}
          placeholder='parolni kiriting'
        />

        <div className="password-strength">
          <p className="password-strength__descr">
            Kalit so'z mustahkamligi
          </p>
          <ProgressBar 
            style={{marginTop: 12}}
            currentStep={passwordStrength}
            stepsCount={4}
            size='sm'
          />

          <h4 className='password-strength__title'>
            Parolingizda quyidagilar bo'lishi kerak:
          </h4>
          <ul className='password-strength__list'>
            <li className='password-strength__list-item'>
              Kamida 8 ta belgi
            </li>

            <li className='password-strength__list-item'>
              Katta va kichik harflar
            </li>

            <li className='password-strength__list-item'>
              Raqam
            </li>

            <li className='password-strength__list-item'>
              Maxsus belgi (%, $, # va boshqalar)
            </li>
          </ul>
        </div>

        <Button 
          size='lg'
          type='submit'
          style={{marginTop: 16}}
          fullWidth
        >
          Ro'yxatdan o'tish
        </Button>
      </form>
    </div>
  )
}

export default SignUp