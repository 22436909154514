// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-sign-in {
  max-width: 500px;
  width: 100%;
  padding: 48px 0;
}
.page-sign-in__question {
  margin-top: 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #2D3748;
  text-align: center;
}
.page-sign-in__question p {
  margin: 0;
}
.page-sign-in__question div {
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
}
.page-sign-in__question div hr {
  margin: 0;
  flex: 1 1;
  border: none;
  border-top: 1px solid;
  border-color: #DFDFDF;
}
.page-sign-in__question .text-underline {
  margin-left: 4px;
  color: #007AFF;
  cursor: pointer;
  text-decoration: underline;
}
.page-sign-in h1 {
  margin: 0;
  margin-bottom: 40px;
  color: #1D1D1D;
  font-size: 28px;
  font-weight: 800;
  line-height: 1.4;
}
.page-sign-in .input-container:nth-child(2) {
  margin-top: 16px;
}
.page-sign-in .form-error {
  margin: 0;
  margin-top: 4px;
  color: var(--error-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/SignIn/styles.sass"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,eAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AACJ;AACI;EACE,SAAA;AACN;AACI;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,eAAA;EACA,gBAAA;AACN;AACM;EACE,SAAA;EACA,SAAA;EACA,YAAA;EACA,qBAAA;EACA,qBAAA;AACR;AACI;EACE,gBAAA;EACA,cAAA;EACA,eAAA;EACA,0BAAA;AACN;AACE;EACE,SAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AAEI;EACE,gBAAA;AAAN;AAEE;EACE,SAAA;EACA,eAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".page-sign-in\n  max-width: 500px\n  width: 100%\n  padding: 48px 0\n  \n  &__question\n    margin-top: 40px\n    font-size: 14px\n    font-weight: 500\n    line-height: 1.5\n    color: #2D3748\n    text-align: center\n\n    p\n      margin: 0\n\n    div\n      margin-top: 18px\n      display: flex\n      align-items: center\n      justify-content: center\n      gap: 8px\n      font-size: 14px\n      font-weight: 400\n\n      hr\n        margin: 0\n        flex: 1\n        border: none\n        border-top: 1px solid\n        border-color: #DFDFDF\n\n    .text-underline\n      margin-left: 4px\n      color: #007AFF\n      cursor: pointer\n      text-decoration: underline\n  \n  h1\n    margin: 0\n    margin-bottom: 40px\n    color: #1D1D1D\n    font-size: 28px\n    font-weight: 800\n    line-height: 1.4\n\n  .input-container\n    &:nth-child(2)\n      margin-top: 16px\n  \n  .form-error\n    margin: 0\n    margin-top: 4px\n    color: var(--error-color)\n    font-size: 14px\n    font-weight: 400\n    line-height: 1.6"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
