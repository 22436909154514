import { ReactComponent as ChevronIcon } from '../../../../assets/global/chevron.svg'
import Button from '../../../common/Button';
import Combobox from '../../../common/Combobox';
import './styles.sass';

const Pagination = () => {
  return (
    <div className='pagination'>
      <div className="pagination__info">
        Showing <span>1-10</span> from <span>2120</span> data
      </div>

      <div className='pagination__buttons'>
        <Button variant='clear' className='pagination__button'>
          <ChevronIcon />
        </Button>

        <div className='pagination__buttons-wrapper'>
          <Button className='pagination__button page-btn page-btn--active' variant='clear'>1</Button>
          <Button className='pagination__button page-btn' variant='clear'>2</Button>
          <Button className='pagination__button page-btn' variant='clear'>3</Button>
          <Button className='pagination__button page-btn' variant='clear'>4</Button>
        </div>

        <Button variant='clear' className='pagination__button pagination__button--right'>
          <ChevronIcon />
        </Button>
        </div>

      <div className='pagination__rows'>
        <p>Show Rows</p>
        <Combobox placeholder='page' options={[{label: 10, value: 10}, {label: 15, value: 15} , {label: 25, value: 25}, {label: 50, value: 50}]} />
      </div>
    </div>
  )
}

export default Pagination