import { NavLink } from "react-router-dom"
import './styles.sass'
import { ReactNode } from "react";


type NavLink = {
  type: 'link';
  to: string;
  icon?: ReactNode;
  text: string;
};

type NavDropDown = {
  type: 'dropdown';
  icon?: ReactNode;
  text: string;
  links: NavLink[];
};

export type NavigationItem = NavLink | NavDropDown;

interface PageNavigationProps {
  links: NavigationItem[];
}

const PageNavigation = ({ links }: PageNavigationProps) => {
  return (
    <div className="page-navigation">
      {links.map(link => {
        if(link.type === 'link')
          return (
            <NavLink to={link.to} key={link.to}>
              <div className="page-navigation__item">
                {link.icon && (
                  <div className="page-navigation__icon">
                    {link.icon}
                  </div>
                )}
                {link.text}
              </div>
            </NavLink>
          );
        
        // TODO: Nav dropdown
      })}
    </div>
  )
}

export default PageNavigation