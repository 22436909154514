import { Outlet } from 'react-router-dom'
import Sidebar from '../../components/dashboard/Sidebar'
import Header from '../../components/dashboard/Header'
import './styles.sass';

const DashboardLayout = () => {
	return (
		<div className='layout layout-dashboard'>
			<div className='container'>
				<Sidebar />
				<div className='layout-dashboard__wrapper'>
					<Header />
					<Outlet />
				</div>
			</div>
		</div>
	)
}

export default DashboardLayout
