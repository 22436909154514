import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ChevronDown } from "../../../utils/icons";
import './styles.sass';

interface ComboboxProps {
  label?: string;
  placeholder?: string;
  defaultValue?: string | number;
  options?: {
    label: string | number;
    value: string | number;
  }[];
  className?: string;
  onSelect?: (value: string | number) => void;
}

const Combobox = ({ label, placeholder = 'Javoblarni tanlang', options = [], onSelect, defaultValue}: ComboboxProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<string | number | null>(defaultValue || null);
  const comboboxRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (comboboxRef.current && !comboboxRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const handleSelect = (value: string | number) => {
    setValue(value);
    if (onSelect) {
      onSelect(value);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);

  const selectedOption = value !== null && options.find((option) => option.value === value);

  return (
    <div className='combobox' ref={comboboxRef}>
      {label && <p className='combobox__label'>{label}</p>}
      <div
        onClick={handleToggle}
        className={classNames(
          'combobox__selected-option',
          { 'combobox__selected-option--active': isOpen }
        )}
      >
        {selectedOption ? selectedOption.label : (
          <span className='combobox__placeholder'>
            {placeholder}
          </span>
        )}

        <div className={classNames('combobox__icon', { 'combobox__icon--active': isOpen })}>
          <ChevronDown />
        </div>
      </div>

      {isOpen && (
        <div className='combobox__options'>
          <div className="combobox__options-wrapper">
            {options?.map((option) => (
              <div
                key={option.value}
                className='combobox__option'
                onClick={() => handleSelect(option.value)}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Combobox;
