// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag {
  display: inline-flex;
  padding: 6.4px 8.5px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  border-radius: 100px;
}
.tag--primary {
  color: #0250EC;
  background: #E8F1FF;
}
.tag--error {
  color: #D20319;
  background: #FFE5E8;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Tag/styles.sass"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,oBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EAIA,oBAAA;AAFF;AAIE;EACE,cAAA;EACA,mBAAA;AAFJ;AAIE;EACE,cAAA;EACA,mBAAA;AAFJ","sourcesContent":[".tag\n  display: inline-flex\n  padding: 6.4px 8.5px\n  font-size: 15px\n  font-weight: 600\n  line-height: 1\n  // white-space: nowrap\n  // overflow: hidden\n  // text-overflow: ellipsis\n  border-radius: 100px\n\n  &--primary\n    color: #0250EC\n    background: #E8F1FF\n\n  &--error\n    color: #D20319\n    background: #FFE5E8"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
