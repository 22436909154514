import { InputHTMLAttributes } from 'react';
import './styles.sass';

interface RadioInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const RadioInput = ({label, ...props}: RadioInputProps) => {
  return (
    <label className='radio-container' >
      <input 
        type='radio' 
        className='radio-circle' 
        {...props}
      />
      {label}
  </label>
  )
}

export default RadioInput