import { ReactComponent as LogoFullColored } from '../../../assets/global/logo-colored.svg'
import { ReactComponent as LogoFullWhite } from '../../../assets/global/logo-white.svg'
import { ReactComponent as LogoIconColored } from '../../../assets/global/logo-icon-colored.svg'
import { ReactComponent as LogoIconWhite } from '../../../assets/global/logo-icon-white.svg'
import { ReactComponent as LogoNew } from '../../../assets/global/new-logo.svg'
import { ReactComponent as LogoNewColored } from '../../../assets/global/new-logo-light-bg.svg'
import './styles.sass'

type Props = {
	type?: 'full' | 'icon'
	viewStyle?: 'colored' | 'white'
	width?: string
	height?: string
}

const Logo = ({ type = 'full', viewStyle = 'colored', width = 'auto', height = 'auto' }: Props) => {
	return (
		<div className={`logo logo-${type}`} style={{width, height}}>
			{type === 'full' && viewStyle === 'colored' && <LogoNewColored />}
			{type === 'full' && viewStyle === 'white' && <LogoNew />}
			{type === 'icon' && viewStyle === 'colored' && <LogoIconColored />}
			{type === 'icon' && viewStyle === 'white' && <LogoIconWhite />}
		</div>
	)
}

export default Logo
