import { YMaps, Map } from '@pbe/react-yandex-maps';
import { PropsWithChildren } from 'react';


const YandexMap: React.FC<PropsWithChildren> = ({children}) => {
  return (
    <YMaps query={{ apikey: '14582ab3-699a-4ff3-b2bb-80850b4fc412', lang: 'ru_RU' }} >
      <Map
        defaultState={{
          center: [41.311365, 69.279593], // Центр карты (например, Москва)
          zoom: 13, // Масштаб карты
        }}
        width="100%"
        height="100%" // Задайте желаемую высоту карты
      >

        {children}

      </Map>
    </YMaps>
  );
};

export default YandexMap;
