// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logistician-tab .drag-n-drop {
  margin-top: 24px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Additional/RolesTabs/LogisticianTab/styles.sass"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ","sourcesContent":[".logistician-tab\n  .drag-n-drop\n    margin-top: 24px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
