// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-additional {
  max-width: 688px;
  width: 100%;
  padding: 48px 0;
}
.page-additional__title {
  margin: 0;
  color: #1D1D1D;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.4;
}
.page-additional .block-title {
  margin: 0;
  margin-top: 24px;
  color: #2F3437;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
}
.page-additional .form {
  margin-top: 32px;
}
.page-additional .form__title {
  margin: 0;
  color: #25262B;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
}
.page-additional .tabs-wrapper {
  margin: 24px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Additional/styles.sass"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,eAAA;AACF;AACE;EACE,SAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AACE;EACE,SAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AACE;EACE,gBAAA;AACJ;AACI;EACE,SAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACN;AACE;EACE,cAAA;AACJ","sourcesContent":[".page-additional\n  max-width: 688px\n  width: 100%\n  padding: 48px 0\n\n  &__title\n    margin: 0\n    color: #1D1D1D\n    font-size: 30px\n    font-weight: 700\n    line-height: 1.4\n\n  .block-title\n    margin: 0\n    margin-top: 24px\n    color: #2F3437\n    font-size: 14px\n    font-weight: 500\n    line-height: 1.4\n\n  .form\n    margin-top: 32px\n\n    &__title\n      margin: 0\n      color: #25262B\n      font-size: 16px\n      font-weight: 600\n      line-height: 1.2\n\n  .tabs-wrapper\n    margin: 24px 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
