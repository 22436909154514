import { ReactNode } from 'react'
import {useNavigate} from "react-router-dom";
import {ChevronDown, PenIcon, PlusIcon, TrashIcon} from "../../../../../utils/icons";
import PageInfo from '../../../../../components/dashboard/PageInfo'
import FlexWrapper from '../../../../../components/common/FlexWrapper'
import Button from "../../../../../components/common/Button";
import DataTable from "../../../../../components/dashboard/DataTable";
import {driversRows} from "../../../../../utils/consts";
import {Column} from "../../../../../types/table.types";
import Tag from "../../../../../components/common/Tag";
import {DriversRow} from "../CompanyDrivers";

const CompanyTrailers = ({ navigationSlot }: {navigationSlot: ReactNode}) => {
  const navigate = useNavigate();

  const columns: Column[] = [
    {
      id: 'driver-name',
      flex: 2,
      headerName: 'Haydovchi ismi',
      renderCell(row: DriversRow) {
        return (
          <div
            style={{cursor: 'pointer'}}
            onClick={() => navigate(`/dashboard/trailers/view`, {
              state: {
                name: row.driverName,
                status: row.status
              }
            })}
          >
            {row.driverName}
          </div>
        )
      },
    },
    {
      id: 'passport-number',
      flex: 1,
      headerName: 'Passport seriyasi va raqami',
      renderCell(row: DriversRow) {
        return <div>{row.passportData}</div>
      },
    },
    {
      id: 'hg-id',
      flex: 1,
      headerName: 'HG seriyasi va raqami',
      renderCell(row: DriversRow) {
        return <div>{row.hgId}</div>
      },
    },
    {
      id: 'car-number',
      flex: 1,
      headerName: 'Mashina raqami',
      renderCell(row: DriversRow) {
        return <div>{row.carNumber}</div>
      },
    },
    {
      id: 'cargo-number',
      flex: 1,
      headerName: 'Yakunlangan yuklar soni',
      renderCell(row: DriversRow) {
        return <div>{row.cargoCount}</div>
      },
    },
    {
      id: 'status',
      flex: 1,
      headerName: 'Status',
      renderCell(row: DriversRow) {
        return <div>{row.status === 'Aktiv'
          ? <Tag text={row.status} color='primary' />
          :  <Tag text={row.status} color='error' />
        }</div>
      },
    },
    {
      id: 'action',
      headerName: '',
      flex: 0.5,
      renderCell(row: DriversRow) {
        return (
          <FlexWrapper>
            <Button variant='clear'>
              <PenIcon />
            </Button>
            <Button variant='clear'>
              <TrashIcon />
            </Button>
          </FlexWrapper>
        )
      },
    },

  ]

  return (
    <div className='page-companies-view'>
      <PageInfo
        title='OOO UMID AVTO LOGISTICS'
        backButton
        url='/dashboard/companies'
        buttonsSlot={
          <FlexWrapper>
            <Button variant='secondary' iconRight={ <ChevronDown width={16} height={16} /> }>
              Ommaviy o’zgartirish
            </Button>
            <Button onClick={() => navigate('/dashboard/trailers/create')} iconLeft={ <PlusIcon stroke="#fff" width={16} height={16} /> }>
              Yangi tirkama qo’shish
            </Button>
          </FlexWrapper>
        }
      />

      <FlexWrapper>
        {navigationSlot}

        <div className="page-content">
          <DataTable columns={columns} rows={driversRows} filterTitle='Tirkamalar' />
        </div>
      </FlexWrapper>
    </div>
  )
}

export default CompanyTrailers
