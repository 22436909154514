// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}
.page-info__wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
.page-info__title {
  margin: 0;
  color: #1F192F;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
}
.page-info__back-button {
  width: 24px;
  height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/PageInfo/styles.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACJ;AACE;EACE,SAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AACE;EACE,WAAA;EACA,YAAA;AACJ","sourcesContent":[".page-info\n  display: flex\n  justify-content: space-between\n  align-items: center\n  gap: 16px\n  margin-bottom: 24px\n\n  &__wrapper\n    display: flex\n    align-items: center\n    gap: 8px\n\n  &__title\n    margin: 0\n    color: #1F192F\n    font-size: 24px\n    font-weight: 500\n    line-height: 1.2\n\n  &__back-button\n    width: 24px\n    height: 24px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
