// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lang-switcher {
  position: relative;
  display: flex;
}
.lang-switcher > button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
.lang-switcher > button svg {
  width: 24px;
  height: 24px;
}
.lang-switcher__text {
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--secondary);
  font-size: 16px;
  line-height: 28px;
}
.lang-switcher__text svg {
  stroke: var(--secondary);
}
.lang-switcher__text--white {
  color: #FFFFFF;
}
.lang-switcher__text--white svg {
  stroke: #FFFFFF;
}

.lang-popup {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  width: 102px;
  background: #FFFFFF;
  border: 1px solid #C5D4DF;
  border-radius: 4px;
  z-index: 10;
}
.lang-popup button {
  padding: 8px 15px;
  gap: 10px;
  color: #1B2226;
  font-weight: 400;
  line-height: 20px;
}
.lang-popup button .lang-content {
  display: flex;
  align-items: center;
  gap: 8px;
}
.lang-popup button:hover {
  background-color: #F5F5F5;
}`, "",{"version":3,"sources":["webpack://./src/components/common/LangSwitcher/styles.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;AACF;AACE;EACE,gBAAA;EACA,YAAA;EACA,eAAA;EACA,UAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;AACN;AACE;EACE,aAAA;EACA,QAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAAI;EACE,wBAAA;AAEN;AADI;EACE,cAAA;AAGN;AAFM;EACE,eAAA;AAIR;;AAHA;EACE,kBAAA;EACA,qBAAA;EACA,QAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,WAAA;AAMF;AAJE;EACE,iBAAA;EACA,SAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;AAMJ;AAJI;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AAMN;AAJI;EACE,yBAAA;AAMN","sourcesContent":[".lang-switcher\n  position: relative\n  display: flex\n\n  & > button\n    background: none\n    border: none\n    cursor: pointer\n    padding: 0\n\n    svg\n      width: 24px\n      height: 24px\n\n  &__text\n    display: flex\n    gap: 4px\n    align-items: center\n    color: var(--secondary)\n    font-size: 16px\n    line-height: 28px\n    svg\n      stroke: var(--secondary)\n    &--white\n      color: #FFFFFF\n      svg\n        stroke: #FFFFFF\n.lang-popup\n  position: absolute\n  top: calc(100% + 8px)\n  right: 0\n  display: flex\n  flex-direction: column\n  padding: 12px 0\n  width: 102px\n  background: #FFFFFF\n  border: 1px solid #C5D4DF\n  border-radius: 4px\n  z-index: 10\n\n  button\n    padding: 8px 15px\n    gap: 10px\n    color: #1B2226\n    font-weight: 400\n    line-height: 20px\n\n    .lang-content\n      display: flex\n      align-items: center\n      gap: 8px\n\n    &:hover\n      background-color: #F5F5F5\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
