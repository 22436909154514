import { FormEvent, useState } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom'
import Button from '../../../components/common/Button';
import OTP from '../../../components/authorization/OTP';
import './styles.sass'

interface LocationState {
  phoneNumber?: string;
}

const Verification = () => {
  const navigate = useNavigate();
  const location: Location<LocationState> = useLocation();
  const phoneNumber = location.state?.phoneNumber;
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [formError, setFormError] = useState('');
  
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const joinedOtp = otp.join("");

    if(joinedOtp.length === 6) {
      console.log(joinedOtp)
      navigate('/auth/new-password')
    } else {
      setFormError('Please enter the OTP code');
    }
  }
  
  return (
    <div className='page-verification'>
      <h1 className='page-verification__title'>
        Tasdiqlash
      </h1>
      <p className='page-verification__descr'>
        Parolni tiklash uchun bir martalik tasdiqlash kodi<br/>
        <span className='phone'>+998 (94) 434 22 11</span> raqamiga yuborildi.
      </p>
      
      <Button onClick={() => navigate(-1)} variant='underline' style={{marginTop: 8}}>
        Raqamni o’zgartirish
      </Button>

      <form onSubmit={onSubmit}>
        <OTP otp={otp} setOtp={setOtp} error={!!formError} />
        {formError && <p className='form-error'>{formError}</p>}

        <p className='timer'>
          Kodni yana yuborish{' '}
          <span className='timer__time'>01:59</span>
        </p>
    
        <Button size='lg' fullWidth type='submit' style={{marginTop: 40}}>
          Tasdiqlash
        </Button>
      </form>
    </div>
  )
}

export default Verification