// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lang-switcher {
  position: relative;
}
.lang-switcher button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.lang-popup {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
.lang-popup button {
  color: #000;
  margin: 0;
  padding: 8px;
  background: none;
  border: none;
  cursor: pointer;
}
.lang-popup button:hover {
  background-color: #f0f0f0;
}`, "",{"version":3,"sources":["webpack://./src/components/common/LangSwitcher/styles.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,gBAAA;EACA,YAAA;EACA,eAAA;EACA,UAAA;AAEJ;;AAAA;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,uBAAA;EACA,sBAAA;EACA,kBAAA;EACA,yCAAA;EACA,aAAA;EACA,sBAAA;AAGF;AADE;EACE,WAAA;EACA,SAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;AAGJ;AADI;EACE,yBAAA;AAGN","sourcesContent":[".lang-switcher \n  position: relative\n  button\n    background: none\n    border: none\n    cursor: pointer\n    padding: 0\n\n.lang-popup\n  position: absolute\n  top: 100%\n  left: 0\n  background-color: white\n  border: 1px solid #ccc\n  border-radius: 4px\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1)\n  display: flex\n  flex-direction: column\n\n  button\n    color: #000\n    margin: 0\n    padding: 8px\n    background: none\n    border: none\n    cursor: pointer\n    \n    &:hover\n      background-color: #f0f0f0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
