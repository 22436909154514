import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ContentWrapper from '../../../components/common/ContentWrapper'
import PageInfo from '../../../components/dashboard/PageInfo'
import Button from '../../../components/common/Button'
import FlexWrapper from '../../../components/common/FlexWrapper'
import Input from '../../../components/common/Input'
import AmountPicker from '../../../components/common/AmountPicker'
import Combobox from '../../../components/common/Combobox'
import DragNDrop from '../../../components/common/DragNDrop'
import { ReactComponent as PlusIcon } from '../../../assets/global/plus.svg';
import { ReactComponent as Trash } from '../../../assets/global/trash.svg';
import { CargoAmounts2 } from '../../../utils/consts'
import './styles.sass'

const CreateCompanies = () => {
  const navigate = useNavigate();

  const [requisites, setRequisites] = useState(Array(1).fill(0));

  // handlers
  const handleCreateRequisites = () => {
    setRequisites([
      ...requisites,
      requisites.length
    ])
  }

  const onSubmit = () => {
    navigate(-1)
  }
  
  return (
    <div className='page-compaines-create'>
      <PageInfo
        title='Yangi korxona yaratish'
        backButton
        buttonsSlot={
          <Button onClick={onSubmit}>
            Saqlash
          </Button>
        }
      />

      <ContentWrapper rowGap={56}>
        <div>
          <h3 className="section-title">Korxona ma'lumotlari</h3>

          <FlexWrapper style={{ marginTop: 16 }}>
            <Input label='Korxona nomi' />
            <Input label='Korxona Rahbari F.I.SH' />
            <Input label='INN' />
          </FlexWrapper>
          
          <FlexWrapper style={{ marginTop: 16}}>
            <AmountPicker 
              label='Transportlar soni'
              amounts={CargoAmounts2}
              onAmountClick={(amount) => console.log(amount)}
              selectedAmount='1-5'
            />
            
            <AmountPicker 
              label='Ishchilar soni'
              amounts={CargoAmounts2}
              onAmountClick={(amount) => console.log(amount)}
              selectedAmount='6-10'
            />
            
            <Combobox label='Tashkil etilgan yili' />
          </FlexWrapper>

          <FlexWrapper style={{ marginTop: 16}}>
            <DragNDrop label='Guvohnomasi fayli' containerSize='sm' />
            <DragNDrop label='Rahbar passporti (oldi)' containerSize='sm' />
            <DragNDrop label='Rahbar passporti (orqa)' containerSize='sm' />
          </FlexWrapper>
        </div>

        <div>
          <h3 className="section-title">Rekvizitlar</h3>

          {requisites.map((requisite, idx) => (
            <ContentWrapper background='ltblue' style={{ marginTop: 16}} key={requisite}>
              <FlexWrapper alignItems='flex-end'>
                <Input label='Bank nomi' containerStyle={{flex: 3}} />
                <Input label='Hisob raqam' containerStyle={{flex: 3}} />
                <Input label='MFO' containerStyle={{flex: 2}} />
                <Combobox 
                  label='Valyuta' 
                  placeholder='UZS'
                  options={[
                    {label: 'UZS', value: 'uzs'},
                    {label: 'USD', value: 'usd'},
                    {label: 'RUB', value: 'rub'},
                  ]}
                />
                <Button 
                  variant='clear'
                  className='remove-btn'
                  disabled={idx === 0}
                  onClick={() => {
                    console.log('1')
                    const newArray = requisites.filter((i) => i !== requisite)
                    setRequisites(newArray)
                  }}
                >
                  <Trash />
                </Button>
              </FlexWrapper>
          </ContentWrapper>
          ))}

          <Button 
            variant='underline'
            style={{marginTop: 16}}
            className='create-requisite-btn'
            iconLeft={<PlusIcon />}
            onClick={handleCreateRequisites}
          >
            Yana qo'shish
          </Button>
        </div>
      </ContentWrapper>
    </div>
  )
}

export default CreateCompanies