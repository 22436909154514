import Button from '../../common/Button'
import { OneIdLogo } from "../../../utils/icons";
import './styles.sass'

const OneIdButton = () => {
  return (
    <Button className='button--one-id' type='button' fullWidth size='lg'>
      <OneIdLogo />
    </Button>
  )
}

export default OneIdButton
