import { Outlet } from 'react-router-dom'
import Header from '../../components/authorization/Header'
import Footer from '../../components/authorization/Footer'
import './styles.sass'

const AuthorizationLayout = () => {
	return (
		<div className='layout layout-authorization'>
			<Header />
			<div className="container">
				<Outlet />
			</div>
			<Footer />
		</div>
	)
}

export default AuthorizationLayout
