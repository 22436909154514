import { useState } from 'react'
import FlexWrapper from '../../../../../components/common/FlexWrapper'
import RadioInput from '../../../../../components/common/RadioInput'
import LegalEnterprise from './LegalEnterprise'
import Individual from './Individual'
import './styles.sass'

type DeleveryType = 'individual' | 'enterprise';

const TruckDeliveryTab = () => {
  const [deliveryType, setDeleveryType] = useState<DeleveryType>('enterprise');
  
  return (
    <div className='truck-delivery-tab'>
        <FlexWrapper label='Yuk tashuvchi turi'>
          <RadioInput 
            label='Yuridik firma'
            checked={deliveryType === 'enterprise'}
            onChange={() => setDeleveryType('enterprise')}
          />
          <RadioInput 
            label='Jismoniy shaxs'
            checked={deliveryType === 'individual'}
            onChange={() => setDeleveryType('individual')}
          />
        </FlexWrapper>

        {deliveryType === 'individual' && <Individual />}
        {deliveryType === 'enterprise' && <LegalEnterprise />}
    </div>
  )
}

export default TruckDeliveryTab