import { ReactComponent as FilterIcon } from '.././../../../assets/global/filter-icon.svg'
import Button from '../../../common/Button';
import './styles.sass';

interface FilterProps {
  title?: string;
}

const Filter = ({title}: FilterProps) => {
  return (
    <div className='filter'>
      {title && (
        <h2 className='filter__title'>{title}</h2>
      )}

      <Button className='filter__button' variant='secondary' iconLeft={<FilterIcon />}>
        Filter
      </Button>
    </div>
  )
}

export default Filter;