import { CSSProperties, ReactNode } from 'react'
import classNames from 'classnames'
import './styles.sass'

interface ContentWrapperProps {
  children?: ReactNode;
  className?: string;
  rowGap?: number | string;
  background?: 'ltblue' | 'white';
  style?: CSSProperties;
}

const ContentWrapper = ({children, className, background = 'white', rowGap = 16, style}: ContentWrapperProps) => {
  const classes = classNames(
    'content-wrapper',
    `content-wrapper--${background}`,
    className
  )
  
  return (
    <div className={classes} style={{rowGap, ...style}}>
      {children}
    </div>
  )
}

export default ContentWrapper