// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp {
  display: flex;
  gap: 16px;
}
.otp__input {
  color: var(--text-default);
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  width: 64px;
  height: 64px;
  border-radius: 4px;
  border: 2px solid var(--border-color);
  transition: border-color 0.2s ease-in, border-width 0.2s ease-in;
}
.otp__input:focus {
  outline: none;
  border-color: var(--primary);
}
.otp__input--error {
  border-color: var(--error-color);
}
.otp__input--error:focus {
  outline: 1px solid var(--error-color);
  border-color: var(--error-color);
}`, "",{"version":3,"sources":["webpack://./src/components/authorization/OTP/styles.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;AACF;AACE;EACE,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EAEA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,qCAAA;EACA,gEAAA;AAAJ;AAEI;EACE,aAAA;EACA,4BAAA;AAAN;AAEI;EACE,gCAAA;AAAN;AAEM;EACE,qCAAA;EACA,gCAAA;AAAR","sourcesContent":[".otp\n  display: flex\n  gap: 16px\n\n  &__input\n    color: var(--text-default)\n    font-size: 20px\n    font-weight: 500\n    line-height: 1.6\n    text-align: center\n\n    width: 64px\n    height: 64px\n    border-radius: 4px\n    border: 2px solid var(--border-color)\n    transition: border-color .2s ease-in, border-width .2s ease-in\n\n    &:focus\n      outline: none\n      border-color: var(--primary)\n\n    &--error\n      border-color: var(--error-color)\n\n      &:focus\n        outline: 1px solid var(--error-color)\n        border-color: var(--error-color)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
