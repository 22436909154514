import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LangUz } from '../../../assets/global/lang-icons/uzbLanguage.svg';
import { ReactComponent as LangRu } from '../../../assets/global/lang-icons/ruLanguage.svg';
import './styles.sass';
import Button from '../Button';

const LangSwitcher = () => {
  const { i18n } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleLanguageSwitch = (lang: string) => {
    i18n.changeLanguage(lang);
    setIsPopupOpen(false);
  };

  return (
    <div className='lang-switcher'>
      <button onClick={togglePopup}>
        {i18n.language === 'ru' ? <LangRu /> : <LangUz />}
      </button>
      {isPopupOpen && (
        <div className='lang-popup'>
          <Button onClick={() => handleLanguageSwitch('ru')}>
            <LangRu /> Русский
          </Button>
          <Button onClick={() => handleLanguageSwitch('uz')}>
            <LangUz /> O'zbekcha
          </Button>
        </div>
      )}
    </div>
  );
};

export default LangSwitcher;
