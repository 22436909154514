// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-container {
  display: grid;
  grid-template-columns: 1em auto;
  color: var(--text-default);
  font-size: 16px;
  line-height: 1.1;
  gap: 8px;
  transition: 0.12s font-weight;
}
.radio-container:has(.radio-circle:checked) {
  font-weight: 600;
}

.radio-circle {
  display: grid;
  place-content: center;
  flex-shrink: 0;
  font: inherit;
  width: 1em;
  height: 1em;
  border: 1px solid var(--secondary);
  border-radius: 50%;
  transform: translateY(1px);
  transition: 120ms border-color ease-in-out;
  appearance: none;
  margin: 0;
  background: #fff;
}
.radio-circle::before {
  content: "";
  width: 0.625em;
  height: 0.625em;
  border-radius: 50%;
  box-shadow: inset 1em 1em var(--primary);
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}
.radio-circle:checked {
  border-color: var(--primary);
}
.radio-circle:checked::before {
  transform: scale(1);
}`, "",{"version":3,"sources":["webpack://./src/components/common/RadioInput/styles.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,+BAAA;EACA,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,QAAA;EACA,6BAAA;AACF;AACE;EACE,gBAAA;AACJ;;AACA;EACE,aAAA;EACA,qBAAA;EACA,cAAA;EACA,aAAA;EACA,UAAA;EACA,WAAA;EACA,kCAAA;EACA,kBAAA;EACA,0BAAA;EACA,0CAAA;EAKA,gBAAA;EACA,SAAA;EACA,gBAAA;AAAF;AAEE;EACE,WAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,wCAAA;EACA,mBAAA;EACA,uCAAA;AAAJ;AAEE;EACE,4BAAA;AAAJ;AAEE;EACE,mBAAA;AAAJ","sourcesContent":[".radio-container\n  display: grid\n  grid-template-columns: 1em auto\n  color: var(--text-default)\n  font-size: 16px\n  line-height: 1.1\n  gap: 8px\n  transition: .12s font-weight\n\n  &:has(.radio-circle:checked)\n    font-weight: 600\n\n.radio-circle\n  display: grid\n  place-content: center\n  flex-shrink: 0\n  font: inherit\n  width: 1em\n  height: 1em\n  border: 1px solid var(--secondary)\n  border-radius: 50%\n  transform: translateY(1px)\n  transition: 120ms border-color ease-in-out\n\n  // reset default styles\n  -webkit-appearance: none\n  -moz-appearance: none\n  appearance: none\n  margin: 0\n  background: #fff\n\n  &::before\n    content: \"\"\n    width: 0.625em\n    height: 0.625em\n    border-radius: 50%\n    box-shadow: inset 1em 1em var(--primary)\n    transform: scale(0)\n    transition: 120ms transform ease-in-out\n  \n  &:checked\n    border-color: var(--primary)\n\n  &:checked::before \n    transform: scale(1)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
