// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-trucks-create {
  padding: 20px 16px 24px 24px;
  min-height: calc(100% - 73px);
}`, "",{"version":3,"sources":["webpack://./src/pages/Trucks/Create/styles.sass"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,6BAAA;AACF","sourcesContent":[".page-trucks-create\n  padding: 20px 16px 24px 24px\n  min-height: calc(100% - 73px)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
