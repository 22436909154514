import { Navigate } from "react-router-dom"
import SignIn from "../pages/Auth/SignIn"
import SignUp from "../pages/Auth/SignUp"
import Restore from "../pages/Auth/Restore"
import Verification from "../pages/Auth/Verification"
import NewPassword from "../pages/Auth/NewPassword"
import Addtional from "../pages/Auth/Additional"
import NotFound from "../pages/NotFound/NotFound"

const authorizationRoutes = [
	{
		index: true,
		element: <Navigate to='sign-in' replace />
	},
	{
		path: 'sign-in',
		element: <SignIn />,
	},
	{
		path: 'sign-up',
		element: <SignUp />,
	},
	{
		path: 'restore',
		element: <Restore />,
	},
	{
		path: 'verification',
		element: <Verification />,
	},
	{
		path: 'new-password',
		element: <NewPassword />,
	},
	{
		path: 'additional-info',
		element: <Addtional />,
	},
	{
    path: '*',
    element: <NotFound />,
  },
]

export default authorizationRoutes
