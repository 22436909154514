// Modal.tsx
import { ReactNode, MouseEvent } from 'react';
import ReactDOM from 'react-dom';
import { CloseIcon } from "../../../utils/icons";
import Button from '../Button';
import classNames from 'classnames';
import './styles.sass';

interface ModalProps {
  children: ReactNode;
  onClose: () => void;
  title?: string;
  titlePosition?: 'left' | 'center'
}

export const Modal = ({ children, onClose, title = '', titlePosition = 'left' }: ModalProps) => {
  const handleBackdropClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div className='modal' onClick={handleBackdropClick}>
      <div className='modal__content'>
        <Button onClick={onClose} className='modal__close-btn' variant='clear'>
          <CloseIcon />
        </Button>
        <h4 className={classNames('modal__title', `modal__title--${titlePosition}`)}>
          {title}
        </h4>

        {children}
      </div>
    </div>,
    document.body
  );
};
