import { useRef, useState } from 'react';
import { companiesDocs } from '../../../utils/consts';
import { TrashIcon, ArrowIcon, FolderIcon, ExternalLink, ImgIcon } from "../../../utils/icons";
import { ModalProps } from '../../../types/modal.types';
import Button from '../Button';
import './styles.sass';
import classNames from 'classnames';

interface AccardionProps {
  onRemoveModal: ModalProps;
}

const Accardion = ({ onRemoveModal }: AccardionProps) => {
  const [activeItem, setActiveItem] = useState<null | number>(null);
  const refs = useRef<(HTMLDivElement | null)[]>([]);

  const { openModal } = onRemoveModal;

  const onItemClick = (id: number, event: React.MouseEvent) => {
    if ((event.target as HTMLElement).closest('button')) {
      return;
    }
    if (activeItem === id) {
      setActiveItem(null);
      return;
    }
    setActiveItem(id);
  };

  const getScrollHeight = (id: number) => {
    return companiesDocs[id].body.reduce((acc, _, idx) => {
      const currentRef = refs.current[idx];
      return currentRef ? acc + currentRef.scrollHeight : 0;
    }, 0);
  };

  return (
    <div className='accardion'>
      {companiesDocs.map(doc => (
        <div
          className={classNames('accardion__item', { 'accardion__item--active': doc.id === activeItem })}
          key={doc.id}
        >
          <div className='accardion__header' onClick={(event) => onItemClick(doc.id, event)}>
            <div className='header-wrapper'>
              <div className='icon-wrapper'>
                <ArrowIcon className={classNames('accardion__arrow', { 'accardion__arrow--active': doc.id === activeItem })} />
                <FolderIcon />
              </div>

              <div className='header-title'>{doc.header}</div>

              <div className='header-date'>03/03/2023</div>
            </div>

            <Button variant='clear' onClick={(event) => {
              event.stopPropagation();
              openModal();
            }}>
              <TrashIcon />
            </Button>
          </div>
          <div
            className='accardion__collapse'
            style={activeItem === doc.id ? { height: getScrollHeight(doc.id) } : { height: 0 }}
          >
            {doc.body.map((item, idx) => (
              <div
                className='accardion__body'
                key={idx}
                ref={el => refs.current[idx] = el}
              >
                <div className='accardion__body-wrapper'>
                  <ImgIcon />

                  <span className='file-name'>
                    {item}
                  </span>

                  <ExternalLink />
                </div>

                <TrashIcon className='body__remove-btn' />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accardion;
