// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal.scss */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal__content {
  position: relative;
  background: white;
  padding: 24px;
  border-radius: 4px;
  max-width: 400px;
  width: 100%;
}
.modal__close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.modal__title {
  margin: 0;
  margin-bottom: 40px;
  padding-right: 48px;
  color: #1F192F;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}
.modal__title--center {
  padding: 0 48px;
  text-align: center;
}
.modal__title--left {
  text-align: left;
}
.modal__btns {
  display: flex;
  gap: 16px;
  margin-top: 32px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Modal/styles.sass"],"names":[],"mappings":"AAAA,eAAA;AACA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF;AACE;EACE,kBAAA;EACA,iBAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;AACJ;AACE;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AACJ;AACE;EACE,SAAA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AACI;EACE,eAAA;EACA,kBAAA;AACN;AACI;EACE,gBAAA;AACN;AACE;EACE,aAAA;EACA,SAAA;EACA,gBAAA;AACJ","sourcesContent":["/* Modal.scss */\n.modal\n  position: fixed\n  top: 0\n  left: 0\n  width: 100%\n  height: 100%\n  background-color: rgba(0, 0, 0, 0.5)\n  display: flex\n  justify-content: center\n  align-items: center\n  z-index: 1000\n\n  &__content\n    position: relative\n    background: white\n    padding: 24px\n    border-radius: 4px\n    max-width: 400px\n    width: 100%\n\n  &__close-btn\n    position: absolute\n    top: 24px\n    right: 24px\n    width: 24px\n    height: 24px\n    overflow: hidden\n\n  &__title\n    margin: 0\n    margin-bottom: 40px\n    padding-right: 48px\n    color: #1F192F\n    font-size: 20px\n    font-weight: 600\n    line-height: 1.2\n\n    &--center\n      padding: 0 48px\n      text-align: center\n\n    &--left\n      text-align: left\n\n  &__btns\n    display: flex\n    gap: 16px\n    margin-top: 32px\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
