import { useState } from "react"
import DragNDrop from "../../../../../../components/common/DragNDrop"
import FlexWrapper from "../../../../../../components/common/FlexWrapper"
import Input from "../../../../../../components/common/Input"
import RadioInput from "../../../../../../components/common/RadioInput"

type TruckOwner = 'own' | 'workshop'

const Individual = () => {
  const [truckOwner, setTruckOwner] = useState<TruckOwner>('own');
  
  return (
    <>
      <FlexWrapper style={{marginTop: 24}}>
        <Input label='Passport seriyasi' placeholder="AB" />
        <Input label='Passport raqami' placeholder="2134521" />
      </FlexWrapper>
      
      <FlexWrapper style={{marginTop: 24}}>
        <DragNDrop 
          containerSize='sm'
          label='Passport (old tomoni)'
          fileTypeDescr='Format .pdf, .jpg or.png yuklang'
        />
        <DragNDrop 
          containerSize='sm'
          label='Passport (orqa tomoni)'
          fileTypeDescr='Format .pdf, .jpg or.png yuklang'
        />
      </FlexWrapper>

      <FlexWrapper style={{marginTop: 24}}>
        <Input label='Haydovchilik guvohnoma seriyasi' placeholder="AF" />
        <Input label='Haydovchilik guvohnoma raqami' placeholder="2134521" />
      </FlexWrapper>

      <FlexWrapper style={{marginTop: 24}}>
        <DragNDrop 
          containerSize='sm'
          label='Haydovchilik guvohnomasi (old tomoni)'
          fileTypeDescr='Format .pdf, .jpg or.png yuklang'
        />
        <DragNDrop 
          containerSize='sm'
          label='Haydovchilik guvohnomasi (orqa tomoni)'
          fileTypeDescr='Format .pdf, .jpg or.png yuklang'
        />
      </FlexWrapper>

      <FlexWrapper label='Mashina kimga tegishli?' wrapperStyle={{marginTop: 56}}>
        <RadioInput 
          label="O'zimniki"
          checked={truckOwner === 'own'}
          onChange={() => setTruckOwner('own')}
        />
        <RadioInput 
          label='Ishxonaga tegishli'
          checked={truckOwner === 'workshop'}
          onChange={() => setTruckOwner('workshop')}
        />
      </FlexWrapper>

      {/* Truck owner types content */}
      {truckOwner === 'own' && (
        <>
          <FlexWrapper style={{marginTop: 24}}>
            <Input label='Mashina raqami' placeholder="01 A 234 BA" />
            <Input label='Mashina texnik passport raqami' placeholder="AF 3425634" />
          </FlexWrapper>

          <FlexWrapper style={{marginTop: 24}}>
            <DragNDrop 
              containerSize='sm'
              label='Mashina texnik passporti (old tomoni)'
              fileTypeDescr='Format .pdf, .jpg or.png yuklang'
            />
            <DragNDrop 
              containerSize='sm'
              label='Mashina texnik passporti (orqa tomoni)'
              fileTypeDescr='Format .pdf, .jpg or.png yuklang'
            />
          </FlexWrapper>
        </>
      )}

      {truckOwner === 'workshop' && (
        <>
          <DragNDrop
            label='Mashina ishonchnomasi'
            fileTypeDescr='Format .pdf, .jpg or.png yuklang'
            style={{marginTop: 24}}
          />
        
          <FlexWrapper style={{marginTop: 24}}>
            <Input label='Mashina raqami' placeholder="01 A 234 BA" />
            <Input label='Mashina texnik passport raqami' placeholder="AF 3425634" />
          </FlexWrapper>

          <FlexWrapper style={{marginTop: 24}}>
            <DragNDrop 
              containerSize='sm'
              label='Mashina texnik passporti (old tomoni)'
              fileTypeDescr='Format .pdf, .jpg or.png yuklang'
            />
            <DragNDrop 
              containerSize='sm'
              label='Mashina texnik passporti (orqa tomoni)'
              fileTypeDescr='Format .pdf, .jpg or.png yuklang'
            />
          </FlexWrapper>
        </>
      )}
    </>
  )
}

export default Individual