// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter {
  padding: 15px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  background: #FFF;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.filter__title {
  margin: 0;
  color: #1F192F;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}
.filter__button {
  color: #4F4F4F;
  border-color: var(--border-color);
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/DataTable/Filter/styles.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;EACA,2BAAA;EACA,4BAAA;AACF;AACE;EACE,SAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AACE;EACE,cAAA;EACA,iCAAA;AACJ","sourcesContent":[".filter\n  padding: 15px 16px\n  display: flex\n  justify-content: space-between\n  align-items: center\n  gap: 16px\n  background: #FFF\n  border-top-left-radius: 4px\n  border-top-right-radius: 4px\n\n  &__title\n    margin: 0\n    color: #1F192F\n    font-size: 20px\n    font-weight: 600\n    line-height: 1.2\n  \n  &__button\n    color: #4F4F4F\n    border-color: var(--border-color)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
