import Logo from '../../components/common/Logo'
import './styles.sass'

const NotFound = () => {
  return (
    <div className='page-not-found'>
      <Logo viewStyle='white' width='45%' height='45%' />
    </div>
  )
}

export default NotFound