// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-sign-up {
  max-width: 500px;
  width: 100%;
  padding: 48px 0;
}
.page-sign-up__title {
  margin: 0;
  color: #1D1D1D;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.4;
}
.page-sign-up form {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.page-sign-up .password-strength__descr {
  margin: 0;
  color: #97999D;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
}
.page-sign-up .password-strength__title {
  margin: 0;
  margin-top: 16px;
  color: #1A1A18;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.3333;
}
.page-sign-up .password-strength__list {
  margin-top: 8px;
  padding-left: 20px;
  list-style: disc;
  color: #3E424A;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.6;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/SignUp/styles.sass"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,eAAA;AACF;AACE;EACE,SAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AACE;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AAEI;EACE,SAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAAN;AAEI;EACE,SAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AAAN;AAEI;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAAN","sourcesContent":[".page-sign-up\n  max-width: 500px\n  width: 100%\n  padding: 48px 0\n  \n  &__title\n    margin: 0\n    color: #1D1D1D\n    font-size: 30px\n    font-weight: 700\n    line-height: 1.4\n\n  form\n    margin-top: 32px\n    display: flex\n    flex-direction: column\n    gap: 16px\n\n  .password-strength\n    &__descr\n      margin: 0\n      color: #97999D\n      font-size: 10px\n      font-weight: 500\n      line-height: 1.2\n\n    &__title\n      margin: 0\n      margin-top: 16px\n      color: #1A1A18\n      font-size: 12px\n      font-weight: 600\n      line-height: 1.3333\n\n    &__list\n      margin-top: 8px\n      padding-left: 20px\n      list-style: disc\n      color: #3E424A\n      font-size: 10px\n      font-weight: 400\n      line-height: 1.6\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
