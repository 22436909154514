// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-wrapper__label {
  margin: 0;
  margin-bottom: 8px;
  color: #2F3437;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
}`, "",{"version":3,"sources":["webpack://./src/components/common/FlexWrapper/styles.sass"],"names":[],"mappings":"AACE;EACE,SAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".flex-wrapper\n  &__label\n    margin: 0\n    margin-bottom: 8px\n    color: #2F3437\n    font-size: 14px\n    font-weight: 500\n    line-height: 1.4"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
