// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-nav {
  padding: 24px;
  background: #fff;
  max-width: 248px;
  width: 100%;
  min-height: calc(100vh - 168px);
  border: 1px solid #E6E6E6;
  border-radius: 4px;
}
.page-nav__item {
  padding: 8px;
  margin-bottom: 16px;
  display: flex;
  gap: 4px;
  align-items: center;
  color: #1F192F;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  border-radius: 4px;
}
.page-nav .active > .page-nav__item {
  color: var(--primary);
  background: #E0EDFF;
}
.page-nav__dropdown {
  color: #1F192F;
  margin-bottom: 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
  padding: 8px;
}
.page-nav__dropdown.open {
  color: var(--primary);
  background: #F4F9FF;
  padding-bottom: 8px;
  padding-right: 8px;
}
.page-nav__dropdown.open .dropdown__body {
  max-height: 2000px;
}
.page-nav__dropdown .dropdown__header {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  cursor: pointer;
}
.page-nav__dropdown .dropdown__body {
  padding-left: 20px;
  margin-top: 8px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease;
}
.page-nav__dropdown .dropdown__body .page-nav__item:last-child {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/common/PageNavigation/styles.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,+BAAA;EACA,yBAAA;EACA,kBAAA;AACF;AACE;EACE,YAAA;EACA,mBAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;EAEA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,qBAAA;EACA,mBAAA;AAAJ;AAEE;EACE,cAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;AAAJ;AAEI;EACE,qBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AAAN;AAEM;EACE,kBAAA;AAAR;AAEI;EAEE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;AADN;AAGI;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,gCAAA;AADN;AAGM;EACE,gBAAA;AADR","sourcesContent":[".page-nav\n  padding: 24px\n  background: #fff\n  max-width: 248px\n  width: 100%\n  min-height: calc( 100vh - 168px )\n  border: 1px solid #E6E6E6\n  border-radius: 4px\n\n  &__item\n    padding: 8px\n    margin-bottom: 16px\n    display: flex\n    gap: 4px\n    align-items: center\n\n    color: #1F192F\n    font-size: 14px\n    font-weight: 500\n    line-height: 1.2\n    border-radius: 4px\n\n  .active > .page-nav__item\n    color: var(--primary)\n    background: #E0EDFF\n\n  &__dropdown\n    color: #1F192F\n    margin-bottom: 16px\n    border-radius: 4px\n    transition: all .3s ease\n    padding: 8px\n\n    &.open\n      color: var(--primary)\n      background: #F4F9FF\n      padding-bottom: 8px\n      padding-right: 8px\n\n      .dropdown__body\n        max-height: 2000px\n\n    .dropdown__header\n      //padding: 8px\n      font-size: 14px\n      font-weight: 500\n      line-height: 1.2\n      cursor: pointer\n\n    .dropdown__body\n      padding-left: 20px\n      margin-top: 8px\n      overflow: hidden\n      max-height: 0\n      transition: max-height 0.2s ease\n\n      .page-nav__item:last-child\n        margin-bottom: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
