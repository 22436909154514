import { ChangeEvent, FormEvent, useState } from 'react';
import Input from '../../../components/common/Input';
import './styles.sass';
import Button from '../../../components/common/Button';

const defaultInputsValues = {
  newPassword: '',
  confirmNewPassword: ''
}

const NewPassword = () => {
  const [inputsValues, setInputsValues] = useState(defaultInputsValues);
  
  // Handlers
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputsValues({
      ...inputsValues,
      [name]: value
    })
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(inputsValues);
  }
  
  return (
    <div className='page-new-password'>
      <h1 className='page-new-password__title'>Yangi parol yarating</h1>

      <form onSubmit={onSubmit}>
        <Input 
          label='Yangi parol'
          type='password'
          name='newPassword'
          value={inputsValues.newPassword}
          onChange={onChangeHandler}
        />
        <Input 
          label='Qayta kiriting'
          type='password'
          name='confirmNewPassword'
          value={inputsValues.confirmNewPassword}
          onChange={onChangeHandler}
        />

        <Button type='submit' fullWidth size='lg' style={{marginTop: 50}}>Saqlash</Button>
      </form>
    </div>
  )
}

export default NewPassword