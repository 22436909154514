import { ReactComponent as OneIdLogo } from '../../../assets/global/one-id-logo.svg'
import Button from '../../common/Button'
import './styles.sass'

const OneIdButton = () => {
  return (
    <Button className='button--one-id' type='button' fullWidth size='lg'>
      <OneIdLogo />
    </Button>
  )
}

export default OneIdButton