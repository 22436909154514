import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../components/common/Logo";
import Button from "../../components/common/Button";
import {
  ComingSoonMailIcon, ComingSoonMapIcon,
  ComingSoonPhoneIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedIn, Sponsor1, Sponsor2, Sponsor3,
  TelegramIcon
} from "../../utils/icons";
import LangSwitcher from "../../components/common/LangSwitcher";
import './styles.sass'
import Input from "../../components/common/Input";

const allowedLanguages = ['uz', 'ru', 'en'];

const ComingSoon = () => {
  const { t, i18n } = useTranslation('comingsoon')

  useEffect(() => {
    const currentLanguage = i18n.language;

    if(!allowedLanguages.includes(currentLanguage)) {
      i18n.changeLanguage('uz');
    }
  })

  return (
    <div className='coming-soon'>
      <div className="intro">
        <div className="blurred-circle circle-1"></div>
        <div className="blurred-circle circle-2"></div>

        <header className="coming-soon__header">
          <Logo viewStyle='white' className='coming-soon__logo' />
          <LangSwitcher whiteTextColor />
        </header>

        <div className="content">
          <h1 className="intro__title">{t("coming soon")}</h1>
          <p className="intro__subtitle">
            {t("comingsoon-subtitle")}
          </p>

          <form className='subscribe-form' onSubmit={(event) => {
            event.preventDefault()
          }}>
            <input
              className='subscribe-form__input'
              type='email'
              name='email'
              placeholder={t('Enter your email')}
            />
            <Button variant="clear" className='subscribe-form__btn'>
              {t("Contact me")}
            </Button>
          </form>
        </div>

        <div className="sponsors">
          <h2 className="sponsors__title">With the support of</h2>
          <div className="sponsors__content">
            {/*<Sponsor1 />*/}
            <Sponsor2 />
            <Sponsor3  />
          </div>
        </div>
      </div>

      <main className="coming-soon__main">
        <h2 className="coming-soon__title">
          {t('For a feedback')}
        </h2>
        <p className="coming-soon__subtitle">
          {t('feedback-text')}
        </p>

        <form className="feedback-form">
          <div className="feedback-form__wrapper">
            <div className="form-group">
              <Input label={t('Name')} placeholder={t('Enter name')} required />
            </div>
            <div className="form-group">
              <Input label={t('Phone number')} placeholder={t('Enter phone number')} required />
            </div>
            <div className="form-group">
              <Input label={t('Email')} placeholder={t('Enter email')} />
            </div>

            <Button variant="clear" className="feedback-form__btn">
              {t('Submit')}
            </Button>
          </div>

          <div className="feedback-form__wrapper">
            <label htmlFor="message" className="feedback-form__label">
              {t('Message')}
            </label>
            <textarea className="feedback-form__textarea" id="message">

            </textarea>
          </div>
        </form>
      </main>

      <footer className="coming-soon__footer">
        <div className="footer-wrapper">
          <div className="footer-card">
            <Logo className='coming-soon__footer-logo' viewStyle='white' />

            <div className="company" dangerouslySetInnerHTML={{ __html: t('logisticPlatform') }} />

            <div className="company-socials">
              <a className='social-links' href="https://facebook.com/yukuzplatform" target="_blank" rel="noopener noreferrer">
                <FacebookIcon/>
              </a>
              <a className='social-links' href="https://www.linkedin.com/company/yukuzplatform" target="_blank" rel="noopener noreferrer">
                <LinkedIn/>
              </a>
              <a className='social-links' href="http://t.me/yukuzplatform" target="_blank" rel="noopener noreferrer">
                <TelegramIcon/>
              </a>
              <a className='social-links' href="https://instagram.com/yukuzplatform" target="_blank" rel="noopener noreferrer">
                <InstagramIcon/>
              </a>
            </div>
          </div>
          <div className="footer-card">
            <div className="footer-card__title">{t('Navigation')}</div>
            <div className="footer-card__content">
              <div>
                <a className='footer-link' href='/#'>{t('News')}</a>
              </div>
              <div>
                <a className='footer-link' href='/#'>{t('International Road Transport')}</a>
              </div>
              <div>
                <a className='footer-link' href='/#'>{t('Useful links')}</a>
              </div>
              <div>
                <a className='footer-link' href='/#'>{t('TSS')}</a>
              </div>
              <div>
                <a className='footer-link' href='/#'>{t('Contacts')}</a>
              </div>
            </div>
          </div>

          <div className="footer-card">
            <div className="footer-card__title">{t('Our contacts')}</div>
            <div className="footer-card__content">
              <div className='contact-wrapper'>
                <a href="tel:+998 77 353 88 88">
                  <ComingSoonPhoneIcon />
                  +998 77 353 88 88
                </a>
              </div>
              <div className='contact-wrapper'>
                <a href="mailto:info@yuk.uz">
                  <ComingSoonMailIcon />
                  info@yuk.uz
                </a>
              </div>
              <div className='contact-wrapper'>
                <a target='_blank' rel="noreferrer" href="https://yandex.uz/maps/10335/tashkent/?from=mapframe&ll=69.211428%2C41.280922&mode=whatshere&source=mapframe&um=constructor%3Acdd612a27d4389278b6fa5b6390cdd20b5afc9f52823e23a159c4f4ba3b7b614&utm_source=mapframe&whatshere%5Bpoint%5D=69.208724%2C41.280736&whatshere%5Bzoom%5D=17&z=17">
                  <ComingSoonMapIcon />
                  <span dangerouslySetInnerHTML={{ __html: t('coming-soon-address') }} />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <span>{t('copyright')}</span>
        </div>
      </footer>
    </div>
  );
};

export default ComingSoon;
