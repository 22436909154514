// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-table__wrapper {
  background: #FFF;
}
.data-table__head {
  display: flex;
  justify-content: space-between;
  padding: 7px;
  border-bottom: 1px solid #E5ECF6;
}
.data-table__head-item {
  padding: 12.5px 16px;
  color: #4A5972;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
}
.data-table__body {
  padding: 8px;
  display: flex;
  flex-direction: column;
}
.data-table__body-cell {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/DataTable/styles.sass"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAEE;EACE,aAAA;EACA,8BAAA;EACA,YAAA;EACA,gCAAA;AAAJ;AAEE;EACE,oBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAAJ;AAEE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;AAAJ;AAEE;EACE,aAAA;EACA,8BAAA;AAAJ","sourcesContent":[".data-table\n  &__wrapper\n    background: #FFF\n\n  &__head\n    display: flex\n    justify-content: space-between\n    padding: 7px\n    border-bottom: 1px solid #E5ECF6\n\n  &__head-item\n    padding: 12.5px 16px\n    color: #4A5972\n    font-size: 14px\n    font-weight: 600\n    line-height: 1.2\n\n  &__body\n    padding: 8px\n    display: flex\n    flex-direction: column\n\n  &__body-cell\n    display: flex\n    justify-content: space-between"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
