
import { ReactNode } from 'react';
import { ArrowLeft } from "../../../utils/icons";
import Button from '../../common/Button';
import './styles.sass'
import { useNavigate } from 'react-router-dom';

interface PageInfoProps {
  title: string;
  backButton?: boolean;
  buttonsSlot?: ReactNode;
  url?: string;
}

const PageInfo = ({title, buttonsSlot, backButton, url}: PageInfoProps) => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    if(url) {
      navigate(url)
      return;
    }

    navigate(-1);
  }

  return (
    <div className='page-info'>
      <div className="page-info__wrapper">
        {backButton && (
          <Button onClick={handleBackButtonClick} className='page-info__back-button' variant='clear'>
            <ArrowLeft />
          </Button>
        )}

        <h1 className='page-info__title'>{title}</h1>
      </div>

      <div className="page-info__buttons">
        {buttonsSlot}
      </div>
    </div>
  )
}

export default PageInfo
