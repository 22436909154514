import AmountPicker from "../../../../../../components/common/AmountPicker"
import Combobox from "../../../../../../components/common/Combobox"
import ContentWrapper from "../../../../../../components/common/ContentWrapper"
import DragNDrop from "../../../../../../components/common/DragNDrop"
import FlexWrapper from "../../../../../../components/common/FlexWrapper"
import Input from "../../../../../../components/common/Input"
import { CargoAmounts2 } from "../../../../../../utils/consts"

const LegalEnterprise = () => {
  return (
    <>
      <FlexWrapper style={{marginTop: 24}}>
          <Input label='Korxona nomi' placeholder='Korxona nomini kiriting' />
          <Input label='INN' placeholder='INN kiriting' />
        </FlexWrapper>

      <FlexWrapper gap={24} style={{marginTop: 24}}>
        <DragNDrop 
          containerSize='sm'
          label='Guvohnomasi fayli'
          fileTypeDescr='Format .pdf, .jpg or.png yuklang'
        />
        <DragNDrop 
          containerSize='sm'
          label='Rahbar passporti (oldi)'
          fileTypeDescr='Format .pdf, .jpg or.png yuklang'
        />
        <DragNDrop 
          containerSize='sm'
          label='Rahbar passporti (orqa)'
          fileTypeDescr='Format .pdf, .jpg or.png yuklang'
        />
      </FlexWrapper>

      <p className="block-title">Rekvizit ma’lumotlari</p>
      <ContentWrapper>

        <FlexWrapper>
          <Input label='Bank nomi' placeholder='Bank nomini kiriting' />
          <Input label='MFO' placeholder='MFO raqami' />
        </FlexWrapper>

        <FlexWrapper>
          <Input label='Hisob raqam' placeholder='Hisob raqaningizni kiriting' />
          <Combobox 
            label='Valyuta'
            defaultValue='uzs'
            options={[
              {label: 'UZS', value: 'uzs'},
              {label: 'USD', value: 'usd'},
              {label: 'RUB', value: 'rub'},
            ]}
          />
        </FlexWrapper>

      </ContentWrapper>

      <FlexWrapper flexWrap="wrap" style={{marginTop: 24}}>
        <AmountPicker 
          gap={8}
          label='Nechta yuk mashinangiz bor?'
          amounts={CargoAmounts2} selectedAmount="1-5"
          onAmountClick={(amount) => console.log(amount)} 
        />
        <AmountPicker 
          gap={8}
          label='Nechta yuk mashinangiz bor?'
          amounts={CargoAmounts2} selectedAmount="6-10"
          onAmountClick={(amount) => console.log(amount)} 
        />
      </FlexWrapper>
    </>
  )
}

export default LegalEnterprise