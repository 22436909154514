import Button from '../../common/Button'
import { PlusIcon, HelpIcon, BellIcon} from "../../../utils/icons";
import './styles.sass'
import LangSwitcher from '../../common/LangSwitcher'

const Header = () => {
  return (
    <div className='header'>
      <div className="header__wrapper">
        <LangSwitcher />

        <Button variant='clear' className='header__button'>
          <BellIcon />
        </Button>
        <Button variant='clear' className='header__button'>
          <HelpIcon />
        </Button>
        <Button variant='clear' className='header__button'>
          <PlusIcon />
        </Button>
      </div>
    </div>
  )
}

export default Header
