import { ReactNode } from 'react'
import { ReactComponent as UploadIcon } from '../../../../../assets/global/upload-icon.svg'
import Accardion from '../../../../../components/common/Accardion'
import ContentWrapper from '../../../../../components/common/ContentWrapper'
import FlexWrapper from '../../../../../components/common/FlexWrapper'
import PageInfo from '../../../../../components/dashboard/PageInfo'
import Button from '../../../../../components/common/Button'
import DragNDrop from '../../../../../components/common/DragNDrop'
import { Modal } from '../../../../../components/common/Modal'
import Combobox from '../../../../../components/common/Combobox'
import useModal from '../../../../../hooks/useModal'
import { createDocSelect } from '../../../../../utils/consts'


const DriverDocs = ({ driverName, navigationSlot }: {driverName: string; navigationSlot: ReactNode}) => {
  const createDocModal = useModal();
  const removeDocModal = useModal();

  return (
    <div className='page-drivers-view'>
      <PageInfo 
        title={driverName}
        url='/dashboard/companies/view/drivers'
        backButton
        buttonsSlot={
          <Button 
            variant='secondary'
            onClick={createDocModal.openModal}
            iconLeft={<UploadIcon width={16} height={16} />}
          >
            Yangi dokument yuklash
          </Button>
        }
      />

      <FlexWrapper>
        {navigationSlot}

        <div className="page-content">
        <ContentWrapper className='company-docs'>
          <h3 className="section-title">Dokumentlar</h3>

          <Accardion onRemoveModal={removeDocModal}  />
        </ContentWrapper>
        </div>
      </FlexWrapper>

      {createDocModal.isOpen && (
        <Modal onClose={createDocModal.closeModal} title='Yangi dokument yuklash'>
          <p className="create-doc__label">Yangi fayl nomi</p>
          <Combobox placeholder='Haydovchilik guvohnomasi' options={createDocSelect} />

          <p className="create-doc__label">Dokument</p>
          <DragNDrop containerSize='sm' />

          <div className="modal__btns">
            <Button variant='secondary' fullWidth>
              Bekor qilish
            </Button>

            <Button fullWidth>
              Saqlash
            </Button>
          </div>
        </Modal>
      )}

      {removeDocModal.isOpen && (
        <Modal 
          onClose={removeDocModal.closeModal}
          titlePosition='center'
          title='Tasdiqlash'
        >
          <p className='remoev-doc__desc'>
            Ha tugmasini bosish orqali ushbu dokument o’chib ketadi. 
            Siz haqiqatdanam bu dokument ni o’chirmoqchimmisiz?
          </p>

          <div className="modal__btns">
            <Button variant='secondary' fullWidth>
              Yo’q, o’chirmayman
            </Button>
            <Button fullWidth>Ha</Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default DriverDocs