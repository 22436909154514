// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-dashboard {
  min-height: 100vh;
  margin: auto;
  background: #F4F7F9;
}
.layout-dashboard .container {
  display: flex;
  height: 100%;
}
.layout-dashboard__wrapper {
  flex: 1 1;
  min-height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/layouts/DashboardLayout/styles.sass"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,YAAA;EACA,mBAAA;AAAJ;AACI;EACE,aAAA;EACA,YAAA;AACN;AACI;EACE,SAAA;EACA,iBAAA;AACN","sourcesContent":[".layout\n  &-dashboard\n    min-height: 100vh\n    margin: auto\n    background: #F4F7F9\n    .container\n      display: flex\n      height: 100%\n\n    &__wrapper\n      flex: 1\n      min-height: 100vh"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
