import { CSSProperties } from 'react';
import './styles.sass'

interface DataViewProps {
  label: string;
  value: string | number
  style?: CSSProperties
}

const DataView = ({label, value, style}: DataViewProps) => {
  return (
    <div className='data-view' style={style}>
      <div className="data-view__label">
        {label}
      </div>

      <div className="data-view__value">
        {value}
      </div>
    </div>
  )
}

export default DataView