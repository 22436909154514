import { useNavigate } from 'react-router-dom';
import Button from '../../../components/common/Button';
import PageInfo from '../../../components/dashboard/PageInfo';
import './styles.sass';
import ContentWrapper from '../../../components/common/ContentWrapper';
import FlexWrapper from '../../../components/common/FlexWrapper';
import Input from '../../../components/common/Input';
import DragNDrop from '../../../components/common/DragNDrop';
import Combobox from '../../../components/common/Combobox';
import RadioInput from '../../../components/common/RadioInput';

const DriversCreate = () => {
  const navigate = useNavigate();
  
  return (
    <div className='page-drivers-create'>
      <PageInfo 
        title='Yangi haydovchi qo’shish'
        backButton 
        buttonsSlot={
          <Button onClick={() => navigate(-1)}>
            Saqlash
          </Button>
        }
      />

      <ContentWrapper rowGap={16}>
        <h3 className="section-title">Haydovchi ma’lumotlari</h3>

        <FlexWrapper>
          <Input label='Haydovchi ismi' placeholder='Ism kiriting' containerStyle={{flex: 3}} />
          <Input label='Familiyasi' placeholder='Familiya kiriting' containerStyle={{flex: 3}} />
          <Input label='Passport seriyasi' placeholder='AB' containerStyle={{flex: 1}} />
          <Input label='Passport raqami' placeholder='123 34 45' containerStyle={{flex: 2}} />
        </FlexWrapper>

        <FlexWrapper>
          <DragNDrop containerSize='sm' label='Passport (old tomoni)' />
          <DragNDrop containerSize='sm' label='Passport (orqa tomoni)' />
        </FlexWrapper>
        
        <FlexWrapper>
          <Input label='Haydovchilik guvohnomasi seriyasi' placeholder='AF' />
          <Input label='Haydovchilik guvohnomasi raqami' placeholder='123 45 67' />
          <Combobox label='Haydovchilik guvohnomasi kategoriyasi' placeholder='BC' />
        </FlexWrapper>

        <FlexWrapper style={{marginBottom: 24}}>
          <DragNDrop containerSize='sm' label='Haydovchilik guvohnomasi (old tomoni)' />
          <DragNDrop containerSize='sm' label='Haydovchilik guvohnomasi (orqa tomoni)' />
        </FlexWrapper>
        
        <h3 className="section-title">Manzil</h3>
        <ContentWrapper background='ltblue'>
          <FlexWrapper>
            <Combobox label='Viloyat' placeholder='Viloyatni tanlang' />
            <Combobox label='Tumanni tanlanf' placeholder='Tumanni tanlanf' />
            <Input label='Ko’cha nomi' placeholder='Ko’cha nomini kiriting' />
            <Input label='Zip kod' placeholder='Zip kod' />
          </FlexWrapper>
        </ContentWrapper>

      </ContentWrapper>

      <ContentWrapper style={{marginTop: 16}}>
        <h3 className="section-title">Muvofiqliklar</h3>

        <FlexWrapper label='Mashina tegishliligi'>
          <RadioInput label="O'zimniki" />
          <RadioInput label='Ishxonaga tegishli' />
        </FlexWrapper>

        <FlexWrapper>
          <Input label='Mashina raqami' placeholder='01 A 123 AA' />
          <Input label='Mashina tehnik passporti raqami' placeholder='AF 123 12 23' />
          <Input label='Yo’naltirilgan tirkama' placeholder='Tirkama nomi' />
        </FlexWrapper>

        <FlexWrapper>
          <DragNDrop label='Mashina tehnik passport  (old tomoni)' containerSize='sm' />
          <DragNDrop label='Mashina tehnik passporti (orqa tomoni)' containerSize='sm' />
        </FlexWrapper>

        <FlexWrapper>
          <Input label='Yo’naltirilgan dispetcher' placeholder='01 A 123 AA' containerStyle={{width: '33%'}} />
          <Input label='Mashina tehnik passporti raqami' placeholder='AF 123 12 23' containerStyle={{width: '33%'}} />
        </FlexWrapper>
      </ContentWrapper>
    </div>
  )
}

export default DriversCreate