// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 160px;
  border-bottom: 1px solid #DFDFDF;
}
.auth-header__button {
  justify-content: center;
  gap: 12px;
  width: 152px;
  height: 46px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 8px;
  border-color: #3366FF;
}
@media screen and (max-width: 1024px) {
  .auth-header {
    padding: 12px 36px;
  }
}
@media screen and (max-width: 600px) {
  .auth-header {
    padding: 12px 16px;
  }
  .auth-header .logo {
    width: 180px !important;
  }
  .auth-header__button {
    width: 120px;
  }
}
@media screen and (max-width: 320px) {
  .auth-header .logo {
    width: 150px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/authorization/Header/styles.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EAEA,mBAAA;EACA,gCAAA;AAAF;AAEE;EACE,uBAAA;EACA,SAAA;EACA,YAAA;EACA,YAAA;EAEA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;AADJ;AAGE;EAnBF;IAoBI,kBAAA;EAAF;AACF;AACE;EAtBF;IAuBI,kBAAA;EAEF;EAAE;IACE,uBAAA;EAEJ;EAAE;IACE,YAAA;EAEJ;AACF;AADE;EACE;IACE,uBAAA;EAGJ;AACF","sourcesContent":[".auth-header\n  display: flex\n  justify-content: space-between\n  align-items: center\n\n  padding: 12px 160px\n  border-bottom: 1px solid #DFDFDF\n\n  &__button\n    justify-content: center\n    gap: 12px\n    width: 152px\n    height: 46px\n\n    font-size: 16px\n    font-weight: 700\n    border-radius: 8px\n    border-color: #3366FF\n\n  @media screen and (max-width: 1024px)\n    padding: 12px 36px\n\n  @media screen and (max-width: 600px)\n    padding: 12px 16px\n\n    .logo\n      width: 180px !important\n\n    &__button\n      width: 120px\n\n  @media screen and (max-width: 320px)\n    .logo\n      width: 150px !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
