import {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, LangEn, LangRu, LangUz, CheckIcon } from '../../../utils/icons';
import Button from '../Button';
import classNames from "classnames";
import './styles.sass';

interface LanguageOptions {
  [key: string]: {
    title: string;
    icon: JSX.Element;
  };
}

const availableLanguages: LanguageOptions = {
  uz: {
    title: "O'Z",
    icon: <LangUz />,
  },
  ru: {
    title: 'РУ',
    icon: <LangRu />,
  },
  en: {
    title: 'EN',
    icon: <LangEn />,
  },
};

const LangSwitcher = ({whiteTextColor = false}: {whiteTextColor?: boolean}) => {
  const { i18n } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleLanguageSwitch = (lang: string) => {
    i18n.changeLanguage(lang);
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        setIsPopupOpen(false);
      }
    };

    if (isPopupOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopupOpen]);

  return (
    <div className="lang-switcher" ref={popupRef}>
      <Button variant="clear" onClick={togglePopup}>
        {availableLanguages[i18n.language]?.icon || <LangEn width={24} height={24} />}
        <span className={classNames('lang-switcher__text', { 'lang-switcher__text--white': whiteTextColor })}>
          {availableLanguages[i18n.language]?.title || 'EN'}
          <ChevronDown />
        </span>
      </Button>
      {isPopupOpen && (
        <div className="lang-popup">
          {Object.keys(availableLanguages).map((lang) => (
            <Button variant="clear" key={lang} onClick={() => handleLanguageSwitch(lang)}>
              <span className='lang-content'>
                {availableLanguages[lang].icon} {availableLanguages[lang].title}
              </span>
              {i18n.language === lang && <CheckIcon />}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LangSwitcher;
