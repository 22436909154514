// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  position: relative;
  flex-shrink: 0;
}
.logo svg {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Logo/styles.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;AAEJ","sourcesContent":[".logo\n  position: relative\n  flex-shrink: 0\n  svg\n    width: 100%\n    height: 100%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
