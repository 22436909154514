// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button--one-id {
  margin-top: 22px;
  padding: 0 24px;
  background: #4825C2;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/authorization/OneIdButton/styles.sass"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;AACF","sourcesContent":[".button--one-id\n  margin-top: 22px\n  padding: 0 24px\n  background: #4825C2\n  border-radius: 8px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
