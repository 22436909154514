// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-navigation {
  padding: 24px;
  background: #fff;
  max-width: 248px;
  width: 100%;
  min-height: calc(100vh - 168px);
  border: 1px solid #E6E6E6;
  border-radius: 4px;
}
.page-navigation__item {
  padding: 8.5px 8px;
  margin-bottom: 16px;
  display: flex;
  gap: 4px;
  align-items: center;
  color: #1F192F;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  border-radius: 4px;
}
.page-navigation .active > .page-navigation__item {
  color: var(--primary);
  background: #E0EDFF;
}`, "",{"version":3,"sources":["webpack://./src/components/common/PageNavigation/styles.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,+BAAA;EACA,yBAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;EAEA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,qBAAA;EACA,mBAAA;AAAJ","sourcesContent":[".page-navigation\n  padding: 24px\n  background: #fff\n  max-width: 248px\n  width: 100%\n  min-height: calc( 100vh - 168px )\n  border: 1px solid #E6E6E6\n  border-radius: 4px\n\n  &__item\n    padding: 8.5px 8px\n    margin-bottom: 16px\n    display: flex\n    gap: 4px\n    align-items: center\n    \n    color: #1F192F\n    font-size: 14px\n    font-weight: 500\n    line-height: 1.2\n    border-radius: 4px\n\n  .active > .page-navigation__item\n    color: var(--primary)\n    background: #E0EDFF\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
