import ContentWrapper from '../../../../../components/common/ContentWrapper'
import Button from '../../../../../components/common/Button'
import FlexWrapper from '../../../../../components/common/FlexWrapper'
import DataView from '../../../../../components/common/DataView'
import { ReactComponent as PenIcon } from '../../../../../assets/global/edit-icon.svg'
import { ReactNode } from 'react'
import PageInfo from '../../../../../components/dashboard/PageInfo'


const CompanyInfo = ({ navigationSlot }: { navigationSlot: ReactNode }) => {
  return (
    <div className='page-companies-view'>
      <PageInfo
        title='OOO UMID AVTO LOGISTICS'
        backButton
        url='/dashboard/companies'
      />

      <FlexWrapper>
        {navigationSlot}

        <div className="page-content">
          <ContentWrapper>
            <div className="section-title">
              Korxona ma'lumotlari

              <Button variant='clear'>
                <PenIcon />
              </Button>
            </div>

            <FlexWrapper>
              <DataView style={{ flex: 1 }} label='Korxona nomi' value='OOO UMID AVTO LOGISTICS' />
              <DataView style={{ flex: 1 }} label='Korxona rahbari' value='Abdukhalim Kurbonov' />
              <DataView style={{ flex: 1 }} label='INN' value='310 492 143' />
            </FlexWrapper>

            <FlexWrapper>
              <DataView style={{ flex: 1 }} label='Transportlar somi' value='35' />
              <DataView style={{ flex: 1 }} label='Ishchilar soni' value='52' />
              <DataView style={{ flex: 1 }} label='Tashkil etilgan yili' value='1998' />
            </FlexWrapper>
          </ContentWrapper>

          <ContentWrapper style={{ marginTop: 24 }}>
            <h3 className="section-title">Rekvizitlar</h3>

            <ContentWrapper background='ltblue'>
              <FlexWrapper>
                <DataView style={{ flex: 2 }} label='Bank nomi' value='JSC “KapitalBank”' />
                <DataView style={{ flex: 2 }} label='Hisob raqam' value='2020 8000 1111 2222 3333' />
                <DataView style={{ flex: 1 }} label='MFO' value='00445' />
                <DataView style={{ flex: 1 }} label='Valyuta' value='UZS' />
                <Button variant='clear'>
                  <PenIcon />
                </Button>
              </FlexWrapper>
            </ContentWrapper>

            <ContentWrapper background='ltblue'>
              <FlexWrapper>
                <DataView style={{ flex: 2 }} label='Bank nomi' value='JSC “KapitalBank”' />
                <DataView style={{ flex: 2 }} label='Hisob raqam' value='2020 8000 1111 2222 3333' />
                <DataView style={{ flex: 1 }} label='MFO' value='00445' />
                <DataView style={{ flex: 1 }} label='Valyuta' value='UZS' />
                <Button variant='clear'>
                  <PenIcon />
                </Button>
              </FlexWrapper>
            </ContentWrapper>
          </ContentWrapper>
        </div>
      </FlexWrapper>
    </div>
  )
}

export default CompanyInfo