import classNames from 'classnames';
import FlexWrapper from '../FlexWrapper';
import './styles.sass';

interface AmountPickerProps {
  gap?: string | number;
  label?: string;
  amounts: string[];
  selectedAmount: string;
  onAmountClick: (amount: string) => void;
}

const AmountPicker = ({gap, label, amounts, selectedAmount, onAmountClick}: AmountPickerProps) => {
  return (
    <div className="amount-picker">
      {label && <p className='amount-picker__label'>{label}</p>}
      
      <FlexWrapper gap={gap} flexWrap='wrap'>
        {amounts.map((amount, idx) => (
          <div
            key={idx} 
            className={classNames(
              'amount-picker__item', 
              {'amount-picker__item--active': selectedAmount === amount}
            )} 
            onClick={() => onAmountClick(amount)}
          >
            {amount}
          </div>
        ))}
      </FlexWrapper>
    </div>
  )
};

export default AmountPicker;