import { CSSProperties, FC, HTMLAttributes } from 'react';
import './styles.sass';

interface FlexWrapperProps extends HTMLAttributes<HTMLDivElement> {
  justifyContent?: 'center' | 'space-between' | 'space-around' | 'flex-start' | 'flex-end';
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch';
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  gap?: string | number;
  label?: string;
  children?: React.ReactNode;
  wrapperStyle?: CSSProperties;
}

const FlexWrapper: FC<FlexWrapperProps> = ({
  justifyContent = 'flex-start',
  alignItems = 'stretch',
  flexDirection = 'row',
  flexWrap = 'nowrap',
  gap = 16,
  label,
  children,
  style,
  wrapperStyle,
  ...props
}) => {
  return (
    <div className='flex-wrapper' style={{...wrapperStyle}}>
      {label && <p className='flex-wrapper__label'>{label}</p>}
      <div
        style={{
          display: 'flex',
          justifyContent,
          alignItems,
          flexDirection,
          flexWrap,
          gap,
          ...style
        }}
        {...props}
      >
        {children}
      </div>
    </div>
  );
}

export default FlexWrapper;
