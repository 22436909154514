// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-view__label {
  color: #4A5972;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
}
.data-view__value {
  margin-top: 8px;
  color: #1F192F;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
}`, "",{"version":3,"sources":["webpack://./src/components/common/DataView/styles.sass"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAAJ;AAEE;EACE,eAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".data-view\n  &__label\n    color: #4A5972\n    font-size: 14px\n    font-weight: 500\n    line-height: 1.2\n\n  &__value\n    margin-top: 8px\n    color: #1F192F\n    font-size: 14px\n    font-weight: 500\n    line-height: 1.2"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
