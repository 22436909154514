import { useLocation, useNavigate } from "react-router-dom"
import Logo from "../../common/Logo"
import Button from "../../common/Button";
import { ReactComponent as ProfileLogo } from '../../../assets/global/profile-user.svg'
import './styles.sass'

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleSignInClick = () => {
    navigate('/auth/sign-in');
  }
  
  return (
    <header className='auth-header'>
      <Logo height='48px' width='216px'/>

      {pathname !== '/auth/sign-in' && (
        <Button 
          variant='outline' 
          onClick={handleSignInClick} 
          className='auth-header__button' 
          iconLeft={<ProfileLogo />}
        >
          Kirish
        </Button>
      )}
    </header>
  )
}

export default Header