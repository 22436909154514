// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 18px;
  width: 100%;
  max-width: 218px;
  border-radius: 4px;
  border: 2px solid var(--border-color);
  transition: border-color 0.2s ease-in;
  cursor: pointer;
}
.role-card--active {
  background: #FFF;
  border-color: var(--primary);
  cursor: default;
}
.role-card:hover {
  border-color: var(--primary);
}
.role-card__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
.role-card__title {
  color: #101113;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/authorization/RoleCard/styles.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,qCAAA;EACA,qCAAA;EACA,eAAA;AACF;AACE;EACE,gBAAA;EACA,4BAAA;EACA,eAAA;AACJ;AACE;EACE,4BAAA;AACJ;AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;AACJ;AACE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AACJ","sourcesContent":[".role-card\n  display: flex\n  align-items: center\n  justify-content: center\n  flex-direction: column\n  gap: 10px\n  padding: 18px\n  width: 100%\n  max-width: 218px\n  border-radius: 4px\n  border: 2px solid  var(--border-color)\n  transition: border-color .2s ease-in\n  cursor: pointer\n\n  &--active\n    background: #FFF\n    border-color: var(--primary)\n    cursor: default\n  \n  &:hover\n    border-color: var(--primary)\n\n  &__icon\n    display: flex\n    align-items: center\n    justify-content: center\n    width: 24px\n    height: 24px\n\n  &__title\n    color: #101113\n    font-size: 14px\n    font-weight: 600\n    line-height: 1.4\n    text-align: center"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
