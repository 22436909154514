import { Navigate, Route, Routes } from "react-router-dom";
import AllItems from "./SubPages/AllItems";
import PageNavigation, { NavigationItem } from "../../components/common/PageNavigation";
import './styles.sass'
import MapDrivers from "./SubPages/MapDrivers";
import MapCargos from "./SubPages/MapCargos";
import NotFound from "../NotFound/NotFound";

const links: NavigationItem[] = [
  {
    type: 'link',
    to: '../all',
    text: "Hammasi"
  },
  {
    type: 'link',
    to: '../cargos',
    text: 'Yuklar'
  },
  {
    type: 'link',
    to: '../drivers',
    text: 'Haydovchilar'
  },
];

const navComponent = <PageNavigation links={links} />

function Map() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to='all' />} />
      <Route path="all" element={<AllItems navigationSlot={navComponent} />} />
      <Route path="cargos" element={<MapCargos navigationSlot={navComponent} />} />
      <Route path="drivers" element={<MapDrivers navigationSlot={navComponent} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Map;