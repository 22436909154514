import { CSSProperties, FC, InputHTMLAttributes, useState } from 'react';
import classNames from 'classnames';
import './styles.sass';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: boolean;
  containerStyle?: CSSProperties;
}

const Input: FC<InputProps> = ({
  type,
  label,
  error,
  containerStyle,
  ...props
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  }

  return (
    <div className='input-container' style={containerStyle}>
      <label className='input-label'>
        {label && (
          <p className='input-label__text'>
            {label}
          </p>
        )}

        <div className='input-wrapper'>
          <input
            className={classNames('input', { 'input--password': type === 'password', 'input--error': error })}
            type={type === 'password' && passwordVisible ? 'text' : type}
            {...props}
          />

          {type === 'password' && (
            <span className="toggle-password-icon" onClick={togglePasswordVisibility}>
              {passwordVisible ? '🙈' : '👁️'}
            </span>
          )}
        </div>
      </label>

      {/* {error && <div className="input-error">{error}</div>} */}
    </div>
  );
}

export default Input;
