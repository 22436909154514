// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary: #3B7CCC;
  --secondary: #757575;
  --text-default: #1E1E1E;
  --border-color: #C8CDD0;
  --auth-bg-color: #F8F8FA;
  --error-color: #F11010;
  --warning-color: #FFFBEB;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *::before, *::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

input, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.section-title {
  margin: 0;
  padding: 8px 2px;
  color: #1F192F;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}`, "",{"version":3,"sources":["webpack://./src/styles/_colors.sass","webpack://./src/styles/global.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,oBAAA;EACA,uBAAA;EACA,uBAAA;EACA,wBAAA;EACA,sBAAA;EACA,wBAAA;ACEF;;AANA;EACE,SAAA;EACA,UAAA;EACA,gCAAA;EACA,yBAAA;EACA,kBAAA;EACA,mCAAA;EACA,kCAAA;AASF;;AAPA;EACE,sBAAA;AAUF;;AARA;EACE,qBAAA;EACA,cAAA;AAWF;;AATA;EACE,gBAAA;EACA,SAAA;EACA,UAAA;AAYF;;AAVA;EACE,oBAAA;EACA,kBAAA;EACA,oBAAA;AAaF;;AAXA;EACE,yBAAA;EACA,WAAA;AAcF;;AAZA;EACE,SAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAeF","sourcesContent":[":root\n  --primary: #3B7CCC\n  --secondary: #757575\n  --text-default: #1E1E1E\n  --border-color: #C8CDD0\n  --auth-bg-color: #F8F8FA\n  --error-color: #F11010\n  --warning-color: #FFFBEB","@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap')\n@import './_colors'\n\nhtml, body\n  margin: 0\n  padding: 0\n  font-family: \"Inter\", sans-serif\n  font-optical-sizing: auto\n  font-style: normal\n  -webkit-font-smoothing: antialiased\n  -moz-osx-font-smoothing: grayscale\n\n*, *::before, *::after\n  box-sizing: border-box\n\na\n  text-decoration: none\n  color: inherit\n\nul, ol\n  list-style: none\n  margin: 0\n  padding: 0\n\ninput, textarea\n  font-family: inherit\n  font-size: inherit\n  line-height: inherit\n\ntable\n  border-collapse: collapse\n  width: 100%\n\n.section-title\n  margin: 0\n  padding: 8px 2px\n  color: #1F192F\n  font-size: 20px\n  font-weight: 600\n  line-height: 1.2"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
