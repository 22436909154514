import Button from '../../common/Button'
import { ReactComponent as PlusIcon } from '../../../assets/global/plus.svg'
import { ReactComponent as HelpIcon } from '../../../assets/global/help-circle.svg'
import { ReactComponent as BellIcon } from '../../../assets/global/bell.svg'
import './styles.sass'
import LangSwitcher from '../../common/LangSwitcher'

const Header = () => {
  return (
    <div className='header'>
      <div className="header__wrapper">
        <LangSwitcher />
        
        <Button variant='clear' className='header__button'>
          <BellIcon />
        </Button>
        <Button variant='clear' className='header__button'>
          <HelpIcon />
        </Button>
        <Button variant='clear' className='header__button'>
          <PlusIcon />
        </Button>
      </div>
    </div>
  )
}

export default Header