import { useNavigate } from "react-router-dom"
import Button from "../../../components/common/Button"
import PageInfo from "../../../components/dashboard/PageInfo"
import DataTable from "../../../components/dashboard/DataTable"
import FlexWrapper from "../../../components/common/FlexWrapper"
import { ReactComponent as PlusIcon } from '../../../assets/global/plus.svg'
import { ReactComponent as EditIcon } from '../../../assets/global/edit-icon.svg'
import { ReactComponent as TrashIcon } from '../../../assets/global/trash.svg'
import { companiesTableData } from "../../../utils/consts"
import { Column } from "../../../types/table.types"
import './styles.sass'

interface CompaniesRow {
  name: string;
  director: string;
  tin: string;
  driversCount: number;
  transportsCount: number;
  createdYear: number;
}

const CompaniesTable = () => {
  const navigate = useNavigate();

  const columns:Column[] = [
    {
      id: 'companiesName',
      headerName: 'Korxona nomi',
      flex: 1,
      renderCell(row) {
        return <div onClick={() => navigate('view')} style={{cursor: "pointer"}}>{row.name}</div>
      },
    },
    {
      id: 'director',
      headerName: 'Korxona rahbari',
      flex: 2,
      renderCell(row) {
        return <div>{row.director}</div>
      },
    },
    {
      id: 'tin',
      headerName: 'INN',
      flex: 1,
      renderCell(row) {
        return <div>{row.tin}</div>
      },
    },
    {
      id: 'driver-count',
      headerName: 'Haydovchilar soni',
      flex: 1,
      renderCell(row: CompaniesRow) {
        return <div>{row.driversCount}</div>
      },
    },
    {
      id: 'transport-count',
      headerName: 'Transportlar soni',
      flex: 1,
      renderCell(row: CompaniesRow) {
        return <div>{row.transportsCount}</div>
      },
    },
    {
      id: 'created-year',
      headerName: 'Tashkil etilgan yili',
      flex: 1,
      renderCell(row: CompaniesRow) {
        return <div>{row.createdYear}</div>
      },
    },
    {
      id: 'action',
      headerName: '',
      flex: 0.5,
      renderCell(row: CompaniesRow) {
        return (
          <FlexWrapper>
            <Button variant='clear'>
              <EditIcon />
            </Button>
            <Button variant='clear'>
              <TrashIcon />
            </Button>
          </FlexWrapper>
        )
      },
    }
  ]

  return (
    <div className='page-companies-table'>
      <PageInfo 
        title='Korxonalarim'
        buttonsSlot={
          <Button
            onClick={() => navigate('create')}
            iconLeft={<PlusIcon width={20} height={20} stroke='#fff' />}
          >
            Yangi korxona yaratish
          </Button>
        }
      />

      <DataTable rows={companiesTableData} columns={columns} />
    </div>
  )
}

export default CompaniesTable