import { ChangeEvent, CSSProperties, FC } from 'react';
import { UploadIcon } from "../../../utils/icons";
import classNames from 'classnames';
import './styles.sass';

interface DragNDropProps {
  accept?: string;
  label?: string;
  containerSize?: 'sm' | 'md';
  fileTypeDescr?: string;
  style?: CSSProperties;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const DragNDrop: FC<DragNDropProps> = ({
  label,
  accept,
  onChange,
  containerSize = 'md',
  fileTypeDescr = 'Format .pdf, .jpg, .jpeg or .png',
  style
}) => {

  return (
    <div className={classNames('drag-n-drop', `drag-n-drop--${containerSize}`)} style={{...style}}>
      {label && <p className="drag-n-drop__label">{label}</p>}
      <label htmlFor="file-upload-input" className='drag-n-drop__wrapper'>

        <div className="upload-icon">
          <UploadIcon />
        </div>

        <div className="file-type-descr">
          {fileTypeDescr} yuklang <br/>
          <span>yoki ko'chirib olib keling</span>
        </div>

      </label>

      <input
        type="file"
        id="file-upload-input"
        accept={accept}
        onChange={onChange}
      />
    </div>
  )
}

export default DragNDrop
