import { ChangeEvent } from 'react';
import Input from '../../../../../components/common/Input'
import DragNDrop from '../../../../../components/common/DragNDrop'
import './styles.sass'

const LogisticianTab = () => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if(fileList?.length)
      console.log(fileList[0]);
  }

  return (
    <div className='logistician-tab'>
      <Input label='Litsenziya raqami' placeholder='Litsenziya raqamini kiriting' />
      <DragNDrop label='Litsenziya fayli'onChange={onChangeHandler} />
    </div>
  )
}

export default LogisticianTab