import classNames from 'classnames';
import './styles.sass';

interface TagProps {
  text: string;
  color: 'primary' | 'error'
}

const Tag = ({ text, color }: TagProps) => {
  return (
    <div className={classNames('tag', `tag--${color}`)}>
      {text}
    </div>
  )
}

export default Tag