// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 4px;
  border: 1px solid #E6E6E6;
}
.content-wrapper--ltblue {
  padding: 16px;
  background: #F2F8FB;
}
.content-wrapper--white {
  background: #FFF;
}`, "",{"version":3,"sources":["webpack://./src/components/common/ContentWrapper/styles.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;AACJ;AACE;EACE,gBAAA;AACJ","sourcesContent":[".content-wrapper\n  display: flex\n  flex-direction: column\n  padding: 24px\n  border-radius: 4px\n  border: 1px solid #E6E6E6\n\n  &--ltblue\n    padding: 16px\n    background: #F2F8FB\n\n  &--white\n    background: #FFF"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
